import React from "react";
import style from "../newApplication/Index.module.css";


export default function AddGroup({setModalWindow,handle , text }){
    return (  <div className={style.modal_container}>
            <div className={style.modal_window}>

                <p>Вы уверены что хотите создать <span >{text}</span>грппу </p>

                <div className={style.butns}>
                    <button className={'add_btn'} onClick={handle}>создать</button>
                    <button className={'sand_btn'}  onClick={() => setModalWindow(false)}>Отменить</button>
                </div>
            </div>
        </div>
    )
}