
import './App.css';
import RouterSettings from "./components/RouterSettings";


function App() {

    return (
        <div className="App">
            <RouterSettings />
        </div>
    );
}

export default App;
