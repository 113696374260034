import React, { useEffect, useState } from "react";
import style from "../newApplication/Index.module.css";
import Select from "react-select";
import deleteIcon from "../../../imgs/icons/24.png";
import icon from "../../../imgs/icons/25gray.png";
import { BaseUrl } from "../../../url";

export default function SupervisorModal({ setModalWindow, handleDelet, id, setStatus, status, setSuperviserCenceled ,status2 ,setModalWindow6}) {
    const [isChecked, setIsChecked] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [quant, setQuant] = useState('');

    const [options, setOptions] = useState([]);

    const myHeaders = new Headers();
    const token = localStorage.getItem("token");

    try {
        const parsedToken = JSON.parse(token);
        myHeaders.append("Authorization", "Bearer " + parsedToken);
    } catch (error) {
        console.error("Ошибка парсинга токена:", error);
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    useEffect(() => {
        fetch(`${BaseUrl}/rejectionreasons/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                const newOptions = result.map(elem => ({ value: elem.reason, label: elem.reason }));
                setOptions([...newOptions]);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            padding: '5px 0',
            backgroundColor: '#FEFAF3',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#FEFAF3',
        })
    };

    // useEffect(() => {
    //     if (isChecked) {
    //         setStatus("Скорректировано");
    //     }
    // }, [isChecked, setStatus]);

    useEffect(() => {
        setSuperviserCenceled([
            { supervisor_approved_value: quant ? quant : null },
            { supervisor_comment: !isChecked ? inputValue : inputValue + ` (квант скорректирован - установлено значение ${quant}) ` }
        ]);
    }, [quant, inputValue, isChecked, setSuperviserCenceled]);

    return (
        <div className={style.modal_container}>
            <div className={style.modal_window}>
                <p>
                    Вы уверены что хотите
                    <span className={status === 'Отклонено' ? style.span :status ==='Скорректировано'?style.span3 : style.span2}>
                        {status === 'Отклонено' ? ' Отклонить ' :status ==='Скорректировано'?' Скорректировать ': " Одобрить "}
                    </span>
                    заявку #{id}
                </p>
                {status === 'Скорректировано' ? (
                    <div className={style.form}>
                        <div>
                            <p>Причина</p>
                            <textarea
                                cols="30"
                                rows="7"
                                placeholder="Введите причину"
                                value={!isChecked ? inputValue : inputValue + ` (квант скорректирован - установлено значение ${quant}) `}
                                onChange={(e) => setInputValue(e.target.value)}
                            ></textarea>
                        </div>
                        <div>
                            <p>Быстрый ответ</p>
                            <Select
                                className={style.select}
                                placeholder='Быстрый ответ'
                                styles={customStyles}
                                options={options}
                                onChange={(option) => setInputValue(inputValue + option.value)}
                            />
                            <p>Квант</p>
                            <div className={style.delete}>
                                <input
                                    placeholder="Введите значение"
                                    className={!isChecked ? style.noActive : ''}
                                    readOnly={!isChecked}
                                    type="number"
                                    value={quant}
                                    onChange={(e) => setQuant(e.target.value)}
                                />
                                <img src={deleteIcon} alt="delete" onClick={() => setQuant('')} />
                            </div>
                            <div
                                className={`${style.delete} ${style.delete1}`}
                                onClick={() => setIsChecked(true)}
                            >
                                <div className={style.iconBlock}>{isChecked ? <img src={icon} alt="" /> : null}</div>
                                Согласовать с корректировкой
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className={style.butns}>
                    <button className='add_btn' onClick={() => handleDelet(status ? status : null , setModalWindow6)}>
                        Отправить
                    </button>
                    <button className='sand_btn' onClick={() => {
                        setModalWindow(false)

                    }}>
                        Отменить
                    </button>
                </div>
            </div>
        </div>
    );
}
