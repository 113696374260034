import React from "react";
import style from "../DistributionCenter/DistributionCenter.module.css";

export default function Grups(){

    return(
        <>
            <h2>Роль </h2>
            <div className={style.table}>
                <div className={style.titles}>
                    <p>Роль</p>
                    <p>Описание</p>
                </div>
                <table className={style.container}>
                    <tr>
                        <td><input className={style.input} type="text" value={'Админ'}/></td>
                        <td><input className={style.input} type="text" value={'Имеет право раздавать роли'}/></td>
                    </tr>
                    <tr>
                        <td><input className={style.input} type="text" value={'Супервайзер'}/></td>
                        <td><input className={style.input} type="text" value={'Имеет право делить товары - Имеет право согласовывать заявки'}/></td>
                    </tr>
                    <tr>
                        <td><input className={style.input} type="text" value={'КАМ'}/></td>
                        <td><input className={style.input} type="text" value={'Имеет право продавать товары - Имеет право создавать заявки'}/></td>
                    </tr>
                </table>
            </div>
        </>
    )
}