import React, { useState, useEffect } from "react";
import style from './Index.module.css';
import logo from '../../imgs/QUANTUM.png';
import importIcon from '../../imgs/icons/excel-import.svg';
import exportIcon from '../../imgs/icons/excel-export.svg';
import importIcon2 from '../../imgs/icons/excel-import2.svg';

import { useNavigate, useLocation } from "react-router-dom";
import {BaseUrl} from "../../url";
import {refreshToken} from "../Tocen";
import Loading from "../../imgs/icons/loader.webp"

export default function Header({ setSection }) {
    const [selected, changeSelected] = useState(0);
    const [loading, setLoading] = useState(false);

    const userRole = JSON.parse(localStorage.getItem("role"));
    const userName = JSON.parse(localStorage.getItem("userName"));
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        userRole !== "admin" ? changeSelected(2) : changeSelected(0);
    }, [userRole]);
    function getCurrentDate() {
        const currentDate = new Date();
        const day = ('0' + currentDate.getDate()).slice(-2);
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        const year = currentDate.getFullYear().toString().slice(-2);
        return `${day}${month}${year}`;
    }

    const formattedDate = getCurrentDate();
    function exportFile() {
        const urlCSV = `${BaseUrl}/export-csv/`;
        const urlExcel = `${BaseUrl}/export-excel/`;
        const token = JSON.parse(localStorage.getItem("token"));
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        const downloadFile = (url, defaultFilename, mimeType) => {
            setLoading(true)
            return fetch(url, {
                method: 'GET',
                headers: myHeaders,
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const contentDisposition = response.headers.get('Content-Disposition');
                    const filenameRegex = /filename="([^"]+)"/;
                    const matches = filenameRegex.exec(contentDisposition);
                    let filename = defaultFilename;
                    if (matches != null && matches[1]) {
                        filename = matches[1];
                    }
                    return response.blob().then(blob => {
                        return { blob, filename, mimeType };
                    });
                })
                .then(({ blob, filename, mimeType }) => {
                    const downloadLink = document.createElement('a');
                    const url = window.URL.createObjectURL(blob);
                    downloadLink.href = url;
                    downloadLink.download = filename;
                    downloadLink.type = mimeType;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(url);
                })
                .catch(error => {
                    console.error(`Error during fetch: ${error}`);
                });
        };

        Promise.all([
            downloadFile(urlCSV, `quants-${new Date().toISOString().split('T')[0]}.csv`, 'text/csv'),
            downloadFile(urlExcel, `unapproved_requests - ${new Date().toISOString().split('T')[0]}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        ]).finally(() => {
            setLoading(false);
        });

    }


    const [impotsWindo,srtImportWindo] = useState(false);
    const [goodRequest,setRequest] =  useState(false);
    const [imgDovlandXY,setImgDounload] = useState('');


    useEffect(()=>{
        setTimeout(()=>{
            setRequest(false)
        },3000)
    },[goodRequest])

    async function handleFileUpload(event, url) {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        srtImportWindo(true);

        const token = JSON.parse(localStorage.getItem("token"));
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        try {
            const response = await fetch(BaseUrl + url, {
                method: 'POST',
                headers: myHeaders,
                body: formData
            });

            if (response.ok) {
                setImgDounload('https://www.pngall.com/wp-content/uploads/2016/07/Success-PNG-Image.png');
            } else {
                throw new Error('Ошибка при загрузке файла');
            }
        } catch (error) {
            console.error('Ошибка:', error);
            setImgDounload('https://cdn.icon-icons.com/icons2/1521/PNG/512/crosscirclehd_106118.png');
        } finally {
            srtImportWindo(false);
            setRequest(true);
        }
    }

    useEffect(() => {
        const hasRefreshed = localStorage.getItem("hasRefreshed");
        const refresh = JSON.parse(localStorage.getItem("refreshToken"));

        if (!hasRefreshed) {
            refreshToken(BaseUrl, refresh);
            localStorage.setItem("hasRefreshed", "true");
        }
    }, []);
    useEffect(() => {
        const refresh = JSON.parse(localStorage.getItem("refreshToken"));

        const updateToken = () => {
            refreshToken(BaseUrl, refresh);
            console.log("Token refreshed"); // Для отладки
        };
        updateToken();

        const intervalId = setInterval(updateToken, 600000); // 60000 мс = 1 минута

        return () => clearInterval(intervalId);
    }, []);

    const wind = <div className={style.width300}>
        <img  className={style.wind} src="https://rb.ru/media/upload_tmp/2018/d3.gif" alt=""/>
    </div>

    return (
        <div className={style.Header}>
            {BaseUrl === 'https://market-admin-dev.bots.winsolutions.ru' ?
                <div className="logo" style={{display: 'flex', alignItems: 'center'}}>
                    <img style={{paddingTop: '5px'}} src={logo} alt="logo"/>
                    <p className={style.test}><span>2.0<span className={style.test}
                                                             style={{paddingTop: '10px'}}>TEST</span></span></p>
                </div>
                : <div className="logo" style={{display: 'flex', alignItems: 'center'}}><img style={{paddingTop: '5px'}}
                                                                                             src={logo} alt="logo"/>  <p
                    className={style.test}><span>2.0</span></p></div>
            }
            <div className="items">
                <nav>
                    <ul>
                        {userRole === "admin" && (
                            <>
                                <li
                                    className={location.pathname.includes('/Referance') ? style.selected : null}
                                    onClick={() => {
                                        changeSelected(0);
                                        navigate('/Referance/');
                                    }}
                                >
                                    Справочники
                                </li>

                                <li
                                    className={location.pathname.includes('/Ligaments/') ? style.selected : null}
                                    onClick={() => {
                                        changeSelected(1);
                                        navigate('/Ligaments/');
                                    }}
                                >
                                    Связки
                                </li>
                            </>
                        )}

                        <li
                            className={location.pathname.includes('/Applications/') ? style.selected : null}
                            onClick={() => {
                                changeSelected(2);
                                navigate('/Applications/');
                            }}
                        >
                            Заявки
                        </li>

                        <li
                            className={location.pathname.includes('/History/') ? style.selected : null}
                            onClick={() => {
                                changeSelected(3);
                                navigate('/History/');
                            }}
                        >
                            История изменений
                        </li>
                    </ul>
                </nav>
            </div>

            <div className={style.userInfo}>
                {userRole === "admin" && (
                    !goodRequest ?   (  !impotsWindo && !goodRequest ? <>
                        <label htmlFor="fileInput2" style={{width: '28px'}}>
                            <img src={importIcon2} alt="" title='Остаток и средняя продажа'/>
                        </label>
                        <label htmlFor="fileInput" style={{width: '28px'}}>
                            <img src={importIcon} alt="" title='"Загрузить" кванты'/>
                        </label>

                        <input id="fileInput2" type="file" onChange={(e) => handleFileUpload(e, '/import-xlsx/')}
                               style={{display: 'none'}}/>
                        <input id="fileInput" type="file" onChange={(e) => handleFileUpload(e, '/import-csv/')}
                               style={{display: 'none'}}/>
                        {loading ? "ddd" :
                            <img src={exportIcon} onClick={exportFile} alt="" title='"Выгрузить" кванты'/>}

                    </> : <div> {wind} </div> ):   <div className={style.width300}><img src={imgDovlandXY} alt=""/>   </div>


                )
                }
                <p>{userName}</p>
                <img src={'https://cdn-icons-png.flaticon.com/512/10141/10141012.png'} alt=""/>
                <button
                    className={style.Btn}
                    onClick={() => {
                        localStorage.removeItem('users');
                        localStorage.removeItem('token');
                        localStorage.removeItem('refreshToken');
                        localStorage.removeItem('role')
                        localStorage.removeItem('userName')
                        localStorage.removeItem('userId')
                        navigate('/login');
                    }}
                >
                    Выход
                </button>
            </div>
            {loading ? <div className={style.blockpage}>
            <div>

                    <img src={Loading} alt=""/>
                    <p>загрузка данных</p>
                    <span>загрузка страницы занимает некоторое время,</span>
                    <span>не обновляйте страницу!</span>
                </div>
            </div> : null}

        </div>
    );
}
