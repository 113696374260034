import React, { useEffect, useState, useRef } from "react";
import Select from 'react-select';
import style from "../GroupsEmployee/GroupsEmployee.module.css";
import { dateFetch } from "../../../../fetch";
import deleteIcon from '../../../../imgs/icons/24.png';
import arrow from '../../../../imgs/icons/system-uicons_arrow.png';
import closeIcon from "../../../../imgs/icons/closeIcon.png";
import ResponseOk from "../../../../components/modalWindos/responseOK/ResponseOk";
import ModalDelete from "../../../../components/modalWindos/modalDeltte/ModalDelete";
import AplicationErrorModal from "../../../../components/modalWindos/AplicationsModal/AplicationErrorModal";
import { BaseUrl } from "../../../../url";
import { refreshToken } from "../../../../components/Tocen";

import LoaderImg from "../../../../imgs/icons/loader.webp";

export default function GroupsCotegorys() {
    const [locationInfo, setLocationInfo] = useState([]);
    const [users, setUsers] = useState([]);
    const [users2, setUsers2] = useState([]);
    const [blockOpen, setBlockOpen] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [newCalegory, setNewCotegory] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [getGrups, setGetGrups] = useState([]);
    const [newUser2, setNewsUser] = useState('');
    const [modalOpen2, setModalOpen2] = useState(false);
    const [newselectedOption, setNewselectedOption] = useState('');
    const [responseOn, setResponseOn] = useState(false);
    const [tileClassName, setTileClassName] = useState(false);
    const [reload, setReload] = useState(false);
    const [modalDelete, setModalDelete] = useState(<></>);
    const [error, setError] = useState(false);

    const [displayedRows, setDisplayedRows] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + JSON.parse(localStorage.getItem("token")));
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    const refresh = JSON.parse(localStorage.getItem("refreshToken"));

    useEffect(() => {
        fetchData();
    }, [reload]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            await Promise.all([
                dateFetch('/categorygroups/', requestOptions, setLocationInfo),
                dateFetch("/categories/", requestOptions, setUsers2),
                dateFetch("/groups/", requestOptions, setGetGrups)
            ]);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUsersWithPagination = async (id) => {
        try {
            const response = await fetch(`${BaseUrl}/categories/get_id/?id=${id}`, requestOptions);
            const data = await response.json();

            setUsers(data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const handleTileClick = async (id, category) => {
        setUsers([]);
        if (tileClassName === id) {
            setTileClassName(null);
        } else {
            setTileClassName(id);
            await fetchUsersWithPagination(category);
        }
    };

    function deleteMembers(id, members, memberToDelete, name) {
        const updatedMembers = members.filter(member => member !== memberToDelete);
        const requestOptionsPost = {
            method: "PATCH",
            headers: myHeaders,
            body: JSON.stringify({
                "id": id,
                "category": updatedMembers,
                "name": name
            })
        };

        dateFetch(`/categorygroups/${id}/`, requestOptionsPost, setLocationInfo);
        setReload(!reload);
        setModalDelete(<></>);
        setResponseOn(true);
    }

    async function changeMembers(id, group) {
        const date = [];
        locationInfo.forEach(e => {
            return e.id === id ? date.push(...e.category) : null;
        });
        selectedUser.map(e => {
            return e.value ? date.push(e.value) : null;
        });
        const requestOptions1 = {
            method: "PATCH",
            headers: myHeaders,
            body: JSON.stringify({
                "group": group,
                "category": [
                    ...date
                ]
            })
        };

        try {
            await dateFetch(`/categorygroups/${id}/`, requestOptions1);
            setResponseOn(true);
            setSelectedUser([]);

            setTimeout(async () => {
                try {
                    await dateFetch('/categorygroups/', requestOptions, setLocationInfo);
                    await handleTileClick(id, date); // Updated to call handleTileClick with the relevant parameters
                    await dateFetch("/groups/", requestOptions, setGetGrups)
                } catch (error) {
                    console.error(error);
                }
            }, 200);
        } catch (error) {
            if (error.response.status === 401) {
                refreshToken(BaseUrl, refresh);
            }
            console.error(error);
        }
    }

    const tableRef = useRef(null);

    const handleScroll = () => {
        const bottom = tableRef.current.scrollHeight - tableRef.current.scrollTop === tableRef.current.clientHeight;
        if (bottom) {
            setDisplayedRows(prevDisplayedRows => prevDisplayedRows + 10);
        }
    };

    const uniqueUsers = Array.from(new Set(users2.map(e => e.ML1)))
        .map(id => {
            return users2.find(e => e.ML1 === id)
        });

    const getFilteredUsers = (category) => {
        return users2.filter((user, index, self) =>
            index === self.findIndex(u => u.ML1 === user.ML1) &&
            !category.includes(user.id) &&
            !category.includes(user.code)
        ).map(user => ({
            value: user.id,
            label: `${user.ML1} ${user.code}`
        }));
    };


    return (
        <div className={style.container}>
            {isLoading && <img src={LoaderImg} alt="Loading..." />}
            {responseOn && <ResponseOk setResponseOn={setResponseOn} />}
            <h2> <pre> </pre></h2>
            <button className={'add_btn'} onClick={() => setModalOpen2(true)}>создать новую</button>
            {modalOpen2 ? (
                <form className={style.modalForm}>
                    <div className={style.inpBlock}>
                        <img className={"closeIcon"} src={closeIcon} onClick={() => setModalOpen2(false)} alt="" />
                        <p>Чтобы добавить группу заполните поля</p>
                        <div className={style.inputsBlock}>
                            <Select
                                options={getGrups
                                    .filter(group => !locationInfo.some(info => info.group === group.id))
                                    .map(e => ({ value: e.id, label: e.name }))
                                }
                                onChange={e => setNewselectedOption(e)}
                                placeholder={"Выберите группу"}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        margin: '10px 0',
                                        background: ' rgba(220, 221, 224, 0.3)',
                                        borderRadius: '8px'
                                    })
                                }}
                            />
                            <Select
                                options={uniqueUsers.map((e) => {
                                    return {
                                        value: e.id,
                                        label: e.ML1
                                    };
                                })}
                                onChange={e => setNewsUser(e)}
                                placeholder={"Выберите категорию"}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        margin: '10px 0',
                                        background: 'rgba(220, 221, 224, 0.3)',
                                        borderRadius: '8px'
                                    })
                                }}
                            />
                        </div>
                        <div className={style.btns}>
                            <button className="add_btn" type="button" onClick={async () => {
                                try {
                                    const response = await fetch(`${BaseUrl}/categorygroups/`, {
                                        method: "POST",
                                        headers: myHeaders,
                                        body: JSON.stringify({
                                            group: newselectedOption.value,
                                            category: [newUser2.value],
                                        })
                                    });

                                    if (response.ok) {
                                        const requestOptions = {
                                            method: "GET",
                                            headers: myHeaders,
                                        };

                                        setResponseOn(true);
                                        setModalOpen2(false);
                                        await fetch(`${BaseUrl}/categorygroups/`, requestOptions).then(res => res.json()).then(setLocationInfo);
                                        await dateFetch("/groups/", requestOptions, setGetGrups);
                                    } else {
                                        setError(true);
                                        setTimeout(() => setError(false), 5000);
                                        setNewselectedOption("");
                                        console.error("Server responded with non-OK status");
                                        setModalOpen2(false);
                                    }
                                } catch (error) {
                                    console.error(error);
                                    setModalOpen2(false);
                                }
                            }}>Создать связку
                            </button>
                            <button className={'sand_btn'} type="button" onClick={() => setModalOpen2(false)}>Отменить
                            </button>
                        </div>
                    </div>
                </form>
            ) : null}
            {locationInfo.length > 0 && locationInfo.map((e, index) => {
                return (
                    <div key={index} onClick={() => setBlockOpen(index)}>
                        <div
                            className={tileClassName === e.id ? style.openTitle : style.tile}
                            onClick={() => handleTileClick(e.id, e.category)}
                        >
                            <p>{getGrups.map(y => {
                                return e.group === y.id ? y.name : null
                            })}</p>
                            <img
                                className={tileClassName === e.id ? style.arrow : style.arrowDown}
                                src={arrow} alt=""
                            />
                        </div>
                        {tileClassName === e.id ? (
                            <div className={`${style.table_container}`} onScroll={handleScroll}>
                                <table className={`${style.table}`} ref={tableRef}>
                                    <tbody style={{ position: "relative" }}>
                                    {(users.length && users.map(e => e) || []).slice(0, displayedRows).map(r => {
                                        if (r) {
                                            return (
                                                <tr key={r.id}>
                                                    <td>
                                                        <input className={style.input} type="text" value={r.ML6} />
                                                    </td>
                                                    <td>
                                                        <input className={style.input} type="text" value={r.code} />
                                                    </td>
                                                    <td>
                                                        <img
                                                            className={style.deleteIcon}
                                                            onClick={() =>
                                                                setModalDelete(
                                                                    <ModalDelete
                                                                        handleDelet={() => deleteMembers(e.id, e.category, r.id, e.ML6)}
                                                                        text={r.ML6}
                                                                        setModalWindow={setModalDelete}
                                                                        text2={getGrups.map(y => {
                                                                            return e.group === y.id ? y.name : null
                                                                        })}
                                                                    />
                                                                )
                                                            }
                                                            src={deleteIcon} alt=""
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        } else {
                                            return <img src={LoaderImg} style={{ position: "absolute", top: 0, left: 0, zIndex: 1000, width: "100px" }} alt="Loading..." />;
                                        }
                                    })}
                                    {selectedUser.length ? selectedUser.map(t => {
                                        const user = users2.find(user => user.id === t.value);
                                        return (
                                            <tr key={t.category}>
                                                <td>
                                                    <input className={`${style.input} ${style.addedUser}`} type="text" value={t.label} />
                                                </td>
                                                <td>
                                                    <input className={`${style.input} ${style.addedUser}`} type="text" value={user?.code || ''} />
                                                </td>
                                            </tr>
                                        );
                                    }) : null}
                                    </tbody>
                                </table>
                                {modalOpen ? (
                                    <form className={style.modalForm}>
                                        <div className={style.inpBlock}>
                                            <img className={"closeIcon"} src={closeIcon}
                                                 onClick={() => setModalOpen(false)} alt="" />
                                            <p>Чтобы добавить новую связку категории заполните поля</p>
                                            <div className={style.inputsBlock}>
                                                <Select
                                                    options={getFilteredUsers(e.category)}
                                                    onChange={(selectedOption) => {
                                                        setSelectedUser([...selectedUser, selectedOption]);
                                                    }}
                                                    value={selectedUser}
                                                    placeholder={"Выберите категорию"}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            margin: '10px 0',
                                                            background: ' rgba(220, 221, 224, 0.3)',
                                                            borderRadius: '8px'
                                                        }),
                                                    }}
                                                />

                                            </div>
                                            <div className={style.btns}>
                                                <button className={'add_btn'} type={'button'} onClick={() => {
                                                    setNewCotegory([...newCalegory, { category: selectedUser }])
                                                    setModalOpen(false)
                                                }}>Создать связку
                                                </button>
                                                <button className={'sand_btn'} type="button" onClick={() => {
                                                    setSelectedUser([])
                                                    setModalOpen(false)
                                                }}>Отменить
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                ) : null}
                                <div className={`${style.btns} ${style.btnsPosition}`} onClick={() => { }}>
                                    <button className={'add_btn'} type={'button'} onClick={() => setModalOpen(true)}>Добавить</button>
                                    <button className={"save_button sand_btn"} onClick={() => changeMembers(e.id, e.group)}>Сохранить</button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                );
            })}
            {error ? <AplicationErrorModal text={"Связка для данной группы уже существует в категориях."} /> : null}
            {modalDelete}
        </div>
    );
}
