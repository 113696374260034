import React, { useState } from "react";
import style from './Reference.module.css';
import DistributionCenter from "./components/DistributionCenter/DistributionCenter";
import Employees from "./components/Employees/Employees";
import Cotegories from './components/Cotegories/Cotegories';
import Grups from "./components/Grups/Grups";
import Roles from "./components/Roles/Roles";
import stulePage from '../Applications/Application.module.css';
import Header from "../../components/header/Header";
import {render} from "@testing-library/react";

export default function Reference({ header }) {
    const [selected, changeSelected] = useState(0);

    const renderSection = () => {
        switch (selected) {
            case 0:
                return <DistributionCenter />;
            case 1:
                return <Employees />;
            case 2:
                return <Cotegories />;
            case 3:
                return <Roles />;
            case 4:
                return <Grups />;
            default:
                return null;
        }
    };

    return (
        <div className={stulePage.searcher && style.container}>
            <Header />
            <div className={style.containerLeft}>
                <h2>Справочники</h2>
                <ul className={style.navbar}>
                    <li className={selected === 0 ? style.selected : null} onClick={() => changeSelected(0)}>РЦ</li>
                    <li className={selected === 1 ? style.selected : null}
                        onClick={() => changeSelected(1)}>Сотрудники
                    </li>
                    <li className={selected === 2 ? style.selected : null} onClick={() => changeSelected(2)}>Категория
                    </li>

                    <li className={selected === 4 ? style.selected : null} onClick={() => changeSelected(4)}>Группы</li>
                    <li className={selected === 3 ? style.selected : null} onClick={() => changeSelected(3)}>Роль</li>
                </ul>
            </div>
            <div className={style.containerRight}>
                {renderSection()}
            </div>
        </div>
    );
}
