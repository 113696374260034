import React, { useEffect, useState } from "react";
import style from "./GroupsEmployee.module.css";
import { dateFetch } from "../../../../fetch";
import deleteIcon from '../../../../imgs/icons/24.png';
import arrow from '../../../../imgs/icons/system-uicons_arrow.png';
import closeIcon from "../../../../imgs/icons/closeIcon.png";
import Select from "react-select";
import ResponseOk from "../../../../components/modalWindos/responseOK/ResponseOk";
import ModalDelete from "../../../../components/modalWindos/modalDeltte/ModalDelete";
import {useNavigate} from "react-router-dom";
import AplicationErrorModal from "../../../../components/modalWindos/AplicationsModal/AplicationErrorModal";
import {BaseUrl} from "../../../../url";

export default function GroupsEmployee() {
    const [locationInfo, setLocationInfo] = useState([]);
    const [users, setUsers] = useState([]);
    const [blockOpen, setBlockOpen] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [newUserCotegories, setNewUserCotegories] = useState([]);
    const [getGrups, setGetGrups] = useState([]);
    const [newUser, setNewUswr] = useState([]);
    const [newselectedOption, setNewselectedOption] = useState('');
    const [newUser2, setNewsUser] = useState('');
    const myHeaders = new Headers();
    const [responseOn, setResponseOn] = useState(false);
    const [tileClassName, setTileClassName] = useState(false);
    const [reload, setReload] = useState(false);
    const [modalDelete,setModalDelete] = useState(<></>);
    const [error,setError] = useState(false);
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    const token = localStorage.getItem("token");
    const navigate = useNavigate();



    if (!token) {
        navigate('/Login');
    } else {
        try {
            const parsedToken = JSON.parse(token);
            myHeaders.append("Authorization", "Bearer " + parsedToken);
        } catch (error) {
            console.error("Ошибка парсинга токена:", error);
            navigate('/Login');
        }
    }
    myHeaders.append("Content-Type", "application/json");
    useEffect(() => {
        dateFetch('/employeegroups/', requestOptions, setLocationInfo);
        dateFetch("/groups/", requestOptions, setGetGrups);
        dateFetch("/api/users/", requestOptions, setUsers);
    }, [locationInfo === [] || reload]);

    function deleteMembers(id,members, memberToDelete, name) {

        const updatedMembers = members.filter(member => member !== memberToDelete);
        const requestOptionsPost = {
            method: "PATCH",
            headers: myHeaders,
            body: JSON.stringify({
                "id": id,
                "members": updatedMembers,
                "name": name
            })
        };

        dateFetch(`/employeegroups/${id}/`, requestOptionsPost, setLocationInfo);
        dateFetch('/employeegroups/', requestOptions, setLocationInfo);
        dateFetch("/groups/", requestOptions, setGetGrups);
        setReload(!reload)
        setResponseOn(true)
        setModalDelete(<></>)
    }


    async function addUserCategories(group, id) {
        const date = newUser.map(e => e.value);

        locationInfo.forEach(e => {
            if (e.id === id) {
                date.push(...e.members);
            }
        });

        const requestOptions1 = {
            method: "PATCH",
            headers: myHeaders,
            body: JSON.stringify({
                "members": date,
                "group": group
            })
        };

        try {
            await dateFetch(`/employeegroups/${id}/`, requestOptions1);
            setResponseOn(true);
        } catch (error) {
            console.error(error);
        } finally {
            setTimeout(async () => {
                try {
                    await dateFetch('/employeegroups/', requestOptions, setLocationInfo);
                    setNewUswr([]);
                } catch (error) {
                    console.error(error);
                }
            }, 200);
        }
    }

    return (
        <div className={style.container}>


            {responseOn ? <ResponseOk setResponseOn={setResponseOn} /> : null}
            <h2> <pre> </pre></h2>
            <button className={'add_btn'} onClick={() => setModalOpen2(true)}>создать новую</button>
            {modalOpen2 ? <form className={style.modalForm}>

                <div className={style.inpBlock}>
                    <img className={"closeIcon"} src={closeIcon} onClick={() => setModalOpen2(false)} alt="" />
                    <p>Чтобы добавить группу заполните поля</p>
                    <div className={style.inputsBlock}>
                        <Select
                            options={getGrups
                                .filter(group => !locationInfo.some(info => info.group === group.id))
                                .map(e => ({ value: e.id, label: e.name }))
                            }
                            onChange={e => setNewselectedOption(e)}
                            placeholder={"Выберите группу"}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    margin: '10px 0',
                                    background: ' rgba(220, 221, 224, 0.3)',
                                    borderRadius: '8px'
                                })
                            }}
                        />
                        <Select
                            options={users.map(e => ({ value: e.id, label: e.username }))}
                            onChange={e => setNewsUser(e)}
                            placeholder={"Выберите пользователя"}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    margin: '10px 0',
                                    background: ' rgba(220, 221, 224, 0.3)',
                                    borderRadius: '8px'
                                })
                            }}
                        />
                    </div>
                    <div className={style.btns}>
                        <button className={'add_btn'} type={'button'} onClick={async () => {
                            try {
                                const response = await fetch(`${BaseUrl}/employeegroups/`, {
                                    method: "POST",
                                    headers: myHeaders,
                                    body: JSON.stringify({
                                        group: newselectedOption.value,
                                        members: [newUser2.value],
                                        user: [newUser2.value]
                                    })
                                });

                                if (response.ok) {
                                    const requestOptions = {
                                        method: "GET",
                                        headers: myHeaders,
                                    };

                                    setResponseOn(true);
                                    setModalOpen2(false);
                                    await fetch(`${BaseUrl}/employeegroups/`, requestOptions).then(res => res.json()).then(setLocationInfo);
                                    await fetch("/groups/", requestOptions).then(res => res.json()).then(setGetGrups);
                                    setModalOpen2(false);
                                } else {
                                    console.error("Server responded with non-OK status");
                                    setError(true);
                                    setTimeout(()=>setError(false),5000)
                                    setModalOpen2(false);
                                }
                            } catch (error) {
                                console.error(error);

                            }
                        }}>Создать связку
                        </button>


                        <button className={'sand_btn'} type="button" onClick={() => setModalOpen2(false)}>Отменить
                        </button>
                    </div>
                </div>
            </form> : null}
            {locationInfo.length ? locationInfo.map((e, index) => {
                return (
                    <div onClick={() => setBlockOpen(index)}>
                        <div
                            className={locationInfo[blockOpen]?.id === e.id && e.id === tileClassName ? style.openTitle : style.tile}
                            onClick={() => setTileClassName(tileClassName === e.id ? '' : e.id)}>
                            <p>{getGrups.map(y => {

                                return e.group === y.id ? y.name : null
                            })}</p>
                            <img
                                className={locationInfo[blockOpen]?.id === e.id && e.id === tileClassName ? style.arrow : style.arrowDown}
                                src={arrow} alt=""/>
                        </div>
                        {locationInfo[blockOpen]?.id === e.id && e.id === tileClassName ? (
                            <div>
                                <table className={style.table}>
                                    {e.members.map(r => {
                                        return users.map(f => {
                                            return f.id === r ? (

                                                <tr key={f.id}>
                                                    <td>
                                                    <input className={style.input} type="text" value={f.username} />
                                                    </td>
                                                    <img className={style.deleteIcon}  onClick={
                                                        () =>  setModalDelete(  <ModalDelete  handleDelet={
                                                            ()=>deleteMembers(e.id,e.members, r, e.name)}
                                                                                              setModalWindow={setModalDelete}
                                                                                              text={f.username}
                                                                                              text2={getGrups.map(y => {

                                                            return e.group === y.id ? y.name : null
                                                        })}/>)
                                                    } src={deleteIcon} alt="" />
                                                </tr>
                                            ) : null
                                        })
                                    })}
                                    {newUser ?
                                        newUser.map(t=> {
                                            return <tr>
                                                <td>
                                                    <input className={`${style.input} ${style.addedUser}`} type="text" value={t.label}/>
                                                </td>
                                            </tr>
                                        })


                                        : null}
                                </table>
                                {modalOpen ? <form className={style.modalForm} onSubmit={''}>

                                <div className={style.inpBlock}>
                                        <img className={"closeIcon"} src={closeIcon} onClick={() => setModalOpen(false)} alt="" />
                                        <p>Чтобы добавить нового пользователя заполните поля</p>
                                        <div className={style.inputsBlock}>
                                            <Select
                                                options={users
                                                    .filter(user => !e.members.includes(user.id) && !newUser.some(selected => selected.value === user.id))
                                                    .map(user => ({
                                                        value: user.id,
                                                        label: user.username
                                                    }))
                                                }
                                                placeholder={"Выберите сотрудника"}
                                                onChange={(selectedOptions) => setNewUswr([...newUser, selectedOptions])}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        margin: '10px 0',
                                                        background: 'rgba(220, 221, 224, 0.3)',
                                                        borderRadius: '8px'
                                                    })
                                                }}
                                            />

                                        </div>
                                        <div className={style.btns}>
                                            <button className={'add_btn'} type={'button'} onClick={() => {
                                                setNewUserCotegories([...newUserCotegories, {
                                                    username: e.username,
                                                    id: e.id,
                                                }])
                                                setModalOpen(false)
                                            }}>Создать связку</button>
                                            <button className={'sand_btn'} type="button" onClick={() => {
                                                setNewUswr([])
                                                setModalOpen(false)
                                            }}>Отменить
                                            </button>
                                        </div>
                                    </div>
                                </form> : null}
                                <div className={"flex_buttons"}>
                                    <button className={'add_btn'} type={'button'} onClick={() => setModalOpen(true)}>Добавить</button>
                                    <button className={'sand_btn'} type="submit" onClick={() => addUserCategories(e.group, e.id)}>Сохранить</button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                )
            }):null}
            {error?<AplicationErrorModal  text={"Связка для данной группы уже существует в категориях."}/>:null}
            {modalDelete}
        </div>
    )
}
