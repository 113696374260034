import React from "react";
import style from "../newApplication/Index.module.css";


export default  function ModalDeleteApplication({setModalWindow,handleDelet , id ,setModalAplication}){


    const  myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + JSON.parse(localStorage.getItem("token")));




  return (  <div className={style.modal_container}>
        <div className={style.modal_window}>

            <p>Вы уверены что хотите Отменить заявку #{id}</p>

                <div className={style.butns}>
                    <button className={'add_btn rose_btn'} onClick={()=> {
                        handleDelet(id)
                        setModalWindow(false)
                    }}>Подтвердить</button>
                    <button className={'sand_btn'}  onClick={() => setModalWindow(false)}>Закрыть </button>
                </div>

        </div>
    </div>
)
}