import React, { useState, useEffect } from "react";
import style from "../DistributionCenter/DistributionCenter.module.css";
import { dateFetch, dateFetch2 } from "../../../../fetch";
import deleteIcon from "../../../../imgs/icons/24.png";
import styleSearch from "../../Reference.module.css";
import ResponseOk from "../../../../components/modalWindos/responseOK/ResponseOk";
import loadingIMG from "../../../../imgs/icons/loader.webp";

export default function Categories() {
    const [locationInfo, setLocationInfo] = useState([]);
    const [newCategories, setCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [responseOn, setResponseOn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [fetchError, setFetchError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 30;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + JSON.parse(localStorage.getItem("token")));

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    const url = "/categories/";

    const fetchData = (page, query) => {
        setLoading(true);
        const queryString = `?page=${page}&search=${query}`;
        dateFetch(`${url}${queryString}`, requestOptions, (data) => {
            setLocationInfo(data.results || []); // Assuming the response has results
            setTotalPages(Math.ceil(data.count / itemsPerPage) || 1);
            setLoading(false);
        });
    };
    console.log(locationInfo)
    useEffect(() => {
        fetchData(currentPage, searchQuery);
    }, [currentPage, searchQuery]);

    myHeaders.append("Content-Type", "application/json");
    const requestOptionsPost = {
        method: "POST",
        redirect: "follow",
        headers: myHeaders,
    };

    function handleSubmit(e) {
        e.preventDefault();

        const updatedRequestOptionsPost = {
            ...requestOptionsPost,
            body: JSON.stringify(newCategories)
        };

        newCategories.forEach(e => {
            +e.code ? setFetchError("Категория с таким кодом уже существует ") : setFetchError("Код должен содержать только цифры")
        });

        if (newCategories.length) {
            dateFetch2(url, updatedRequestOptionsPost)
                .then(() => {
                    setResponseOn(true)
                    setCategories([]);
                    setFetchError('');
                    return fetchData(currentPage, searchQuery);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    console.log('Request failed:', error.message);
                });
        }
    }

    function handleNewCategories() {
        const ML1Input = document.getElementsByName("ML1")[0];
        const ML2Input = document.getElementsByName("ML2")[0];
        const ML3Input = document.getElementsByName("ML3")[0];
        const ML4Input = document.getElementsByName("ML4")[0];
        const ML5Input = document.getElementsByName("ML5")[0];
        const ML6Input = document.getElementsByName("ML6")[0];
        const codeInput = document.getElementsByName("code")[0];

        const ML1 = ML1Input.value;
        const ML2 = ML2Input.value;
        const ML3 = ML3Input.value;
        const ML4 = ML4Input.value;
        const ML5 = ML5Input.value;
        const ML6 = ML6Input.value;
        const code = codeInput.value;

        if (!ML1 || !ML2 || !ML3 || !ML4 || !ML5 || !ML6 || !code) {
            console.error("Please fill in all required fields.");

            if (!ML1) ML1Input.classList.add(style.errorInput);
            else ML1Input.classList.remove(style.errorInput);

            if (!ML2) ML2Input.classList.add(style.errorInput);
            else ML2Input.classList.remove(style.errorInput);

            if (!ML3) ML3Input.classList.add(style.errorInput);
            else ML3Input.classList.remove(style.errorInput);

            if (!ML4) ML4Input.classList.add(style.errorInput);
            else ML4Input.classList.remove(style.errorInput);

            if (!ML5) ML5Input.classList.add(style.errorInput);
            else ML5Input.classList.remove(style.errorInput);

            if (!ML6) ML6Input.classList.add(style.errorInput);
            else ML6Input.classList.remove(style.errorInput);

            if (!code) codeInput.classList.add(style.errorInput);
            else codeInput.classList.remove(style.errorInput);

            return;
        }

        const newCategory = {
            "ML1": ML1,
            "ML2": ML2,
            "ML3": ML3,
            "ML4": ML4,
            "ML5": ML5,
            "ML6": ML6,
            "code": code
        };

        setCategories([...newCategories, newCategory]);

        ML1Input.value = '';
        ML2Input.value = '';
        ML3Input.value = '';
        ML4Input.value = '';
        ML5Input.value = '';
        ML6Input.value = '';
        codeInput.value = '';
    }

    const filteredLocationInfo =locationInfo && locationInfo.filter(item => {
        const fields = ["ML1", "ML2", "ML3", "ML4", "ML5", "ML6", "code"];
        const matched = fields.some(field => {
            const fieldValue = '' + item[field];
            if (typeof fieldValue === "string") {
                return fieldValue.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
        });
        return matched;
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredLocationInfo.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <>
            {responseOn ? <ResponseOk setResponseOn={setResponseOn} /> : null}
            <h2>Категории</h2>
            <input
                className={styleSearch.search}
                type="search"
                placeholder="Поиск"
                value={searchQuery}
                onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setCurrentPage(1);
                }}
            />
            {loading ? (
                <div className={'ladingBlock'}>
                    <img src={loadingIMG} alt="loading" />
                </div>
            ) : (
                <div className={style.table}>
                    <div className={style.titles}>
                        <p>ML1</p>
                        <p>ML2</p>
                        <p>ML3</p>
                        <p>ML4</p>
                        <p>ML5</p>
                        <p>ML6</p>
                        <p>Код</p>
                    </div>
                    <table className={style.container}>
                        {locationInfo.map(e => (
                            <tr key={e.id}>
                                <td><input className={style.input} type="text" value={e.ML1} readOnly /></td>
                                <td><input className={style.input} type="text" value={e.ML2} readOnly /></td>
                                <td><input className={style.input} type="text" value={e.ML3} readOnly /></td>
                                <td><input className={style.input} type="text" value={e.ML4} readOnly /></td>
                                <td><input className={style.input} type="text" value={e.ML5} readOnly /></td>
                                <td><input className={style.input} type="text" value={e.ML6 ? e.ML6 : ''} readOnly /></td>
                                <td><input className={style.input} type="text" value={e.code} readOnly /></td>
                            </tr>
                        ))}
                        {newCategories.map((e, index) => (
                            <tr className={style.added} key={index}>
                                <td><input className={style.input} type="text" value={e.ML1} /></td>
                                <td><input className={style.input} type="text" value={e.ML2} /></td>
                                <td><input className={style.input} type="text" value={e.ML3} /></td>
                                <td><input className={style.input} type="text" value={e.ML4} /></td>
                                <td><input className={style.input} type="text" value={e.ML5} /></td>
                                <td><input className={style.input} type="text" value={e.ML6} /></td>
                                <td><input
                                    className={fetchError ? `${style.input} ${style.errorInput}` : style.input}
                                    type="text"
                                    value={e.code}
                                    onChange={(event) => {
                                        const updatedCategories = [...newCategories];
                                        updatedCategories[index].code = event.target.value;
                                        setCategories(updatedCategories);
                                    }}
                                /></td>

                                <img
                                    onClick={() => {
                                        const updatedAddRC = newCategories.filter((item, i) => i !== index);
                                        setCategories(updatedAddRC);
                                    }}
                                    className={style.deleteIcon}
                                    src={deleteIcon}
                                    alt="delete"
                                />
                            </tr>
                        ))}
                    </table>
                </div>
            )}

            <form onSubmit={handleSubmit}>
                <div className={style.inputsBlock} onChange={() => setFetchError('')}>
                    <input className={`${style.input}`} type="text" name="ML1" placeholder="ML1" />
                    <input className={`${style.input}`} type="text" name="ML2" placeholder="ML2" />
                    <input className={`${style.input}`} type="text" name="ML3" placeholder="ML3" />
                    <input className={`${style.input}`} type="text" name="ML4" placeholder="ML4" />
                    <input className={`${style.input}`} type="text" name="ML5" placeholder="ML5" />
                    <input className={`${style.input}`} type="text" name="ML6" placeholder="ML6" />
                    <input className={`${style.input}`} type="text" name="code" placeholder="Код" />
                </div>
                <p className={style.error}>{fetchError}</p>

                <div className={style.btns}>
                    <div className={style.pagination}>

                            <button onClick={() => paginate(1)} disabled={currentPage === 1}>{"<<"}</button>
                            <button onClick={() => paginate(currentPage-1)} disabled={currentPage === 1}>{"<"}</button>
                            {currentPage > 0 && (
                            <>
                                {/*<button onClick={() => paginate(currentPage>1?currentPage - 1:null)}>{currentPage - 1}</button>*/}
                                <span className={style.checked}>{currentPage}</span>
                            </>
                        )}
                        {pageNumbers.slice(currentPage, currentPage + 1).map(number => (
                            <button key={number} onClick={() => paginate(number)}
                                    className={currentPage === number ? style.active : ''}
                            >

                                {number}
                            </button>
                        ))}
                        <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>{">"}</button>
                        <button onClick={() => paginate(totalPages )} disabled={currentPage === totalPages}>{">>"}</button>
                    </div>
                    <button className={'add_btn'} type={'button'} onClick={handleNewCategories}>Добавить</button>
                    <button className={newCategories.length ? "add_btn" : 'sand_btn'} type="submit">Сохранить</button>
                </div>
            </form>
        </>
    );
}
