import React, { useState, useEffect, useCallback } from "react";
import style from '../reference/components/DistributionCenter/DistributionCenter.module.css';
import stulePage from './Application.module.css';
import filterIcon from '../../imgs/icons/Frame 1948755655.png';
import sortIcon from '../../imgs/icons/Frame 1948755654.png';
import NewApplication from "../../components/modalWindos/newApplication/Index";
import under_review from '../../imgs/icons/Property 1=Default1.png';
import draft from '../../imgs/icons/Property 1=Default0.png';
import draftx from '../../imgs/photo_2024-07-15 20.05.39.jpeg';
import approved from '../../imgs/icons/одобрено.svg';
import rejected from '../../imgs/icons/Vector.svg';
import InProcessing from '../../imgs/icons/Property 1=Default2.png';
import Uploaded from '../../imgs/icons/Property 1=Default3.png';
import { dateFetch } from "../../fetch";

import KamModal from "../../components/modalWindos/kamModal/Indx";
import SupervisorModal from "../../components/modalWindos/supervisorModal";
import Filter from "../../components/filter/Index";
import Header from "../../components/header/Header";
import ResponseOk from "../../components/modalWindos/responseOK/ResponseOk";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../url";
import propertyIcon from '../../imgs/Property 1=Variant2 (2).png';
import ModalDeleteApplication from "../../components/modalWindos/ModalAplicationDelete/Indx";
import { debounce } from 'lodash';

function useModals() {
  const [modals, setModals] = useState({
    modalWindow: false,
    modalWindow2: false,
    modalWindow3: false,
    modalWindow4: false,
    modalWindow5: false,
    modalWindow6: false,
    modalWindow7: false,
    modalWindow8: false,
  });

  const openModal = useCallback((modalName) => {
    setModals(prev => ({ ...prev, [modalName]: true }));
  }, []);

  const closeModal = useCallback((modalName) => {
    setModals(prev => ({ ...prev, [modalName]: false }));
  }, []);

  return { modals, openModal, closeModal };
}

export default function Applications() {
  const [status, setStatus] = useState('');
  const [locationInfo, setLocationInfo] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [newID, setNewID] = useState();
  const [pinkLine, setPinkLine] = useState();
  const [start, setStart] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterOptions, setFilterOptions] = useState('');
  const [modalAplication, setModalAplication] = useState(<></>);
  const [apdateID, setApdateID] = useState('');

  const [startF, serStartF] = useState(true);

  const [selectedIds, setSelectedIds] = useState([]);
  const [approved_by, setApproved_by] = useState("");
  const [sortDate, setSortDate] = useState(true);
  const [sortStatus, setSortStatus] = useState(false);
  const [superviserCenceled, setSuperviserCenceled] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const myHeaders = new Headers();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [dateIndex, setDateIndex] = useState();
  const [spred, setSpred] = useState(false);
  const [startFilter, srtStartFilter] = useState(false)
  const [params, setParams] = useState(null)
  const [searchQueryGet, setSearchQueryGet] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const [total, setTotal] = useState(1)
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState('desc');

  const { modals, openModal, closeModal } = useModals();

  const [lastModalState, setLastModalState] = useState({});
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  localStorage.setItem('lastRequestUrl', '');
  useEffect(() => {
    const modalStateChanged = JSON.stringify(modals) !== JSON.stringify(lastModalState);

    if (modalStateChanged) {
      setIsRequestInProgress(true);
      const timer = setTimeout(() => {
        if (isRequestInProgress) {
          loadData(1);
        }
        setIsRequestInProgress(false);
      }, 3000);
      setLastModalState(modals);

      return () => clearTimeout(timer);
    }
  }, [modals, isRequestInProgress]);

  if (!token) {
    navigate('/Login');
  } else {
    try {
      const parsedToken = JSON.parse(token);
      myHeaders.append("Authorization", "Bearer " + parsedToken);
    } catch (error) {
      console.error("Ошибка парсинга токена:", error);
      navigate('/Login');
    }
  }
  const requestOptionsChangeStatus = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      change_request: newID,
      approved_by: JSON.parse(localStorage.getItem('userId'))
    })
  };
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  const filterFieldMappings = {
    "Автор": "kam_user",
    "Категория": "category",
    "РЦ": "warehouse",
    "Текущ_квант": "current_quant",
    "Квант": "quant",
    // "Max_квант": "max_quant",
    "ОТЗ": "turnover",
    "Статус": "status"
  };
  const [currentPage, setCurrentPage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lastLoadedPage, setLastLoadedPage] = useState(0);
  const [nextPageToLoad, setNextPageToLoad] = useState(1);

  const loadData = useCallback((pageNum = 1) => {
    if (isLoading) {
      console.log('A request is already in progress. Skipping this one.');
      return;
    }

    setIsLoading(true);
    setLoadingData(true);

    const filters = Object.keys(selectedFilter)
        .filter(key => selectedFilter[key] !== undefined && selectedFilter[key] !== '')
        .map(key => `${filterFieldMappings[key]}=${encodeURIComponent(selectedFilter[key])}`)
        .join('&');

    let ordering = '';
    if (sortedColumn === 'status') {
      ordering = `ordering=${sortDirection === 'desc' ? '' : 'status'}`;
    } else if (sortedColumn === 'created_at') {
      ordering = `ordering=${sortDirection === 'desc' ? '-' : ''}created_at`;
    } else if (sortedColumn) {
      ordering = `ordering=${sortDirection === 'desc' ? '-' : ''}${sortedColumn}`;
    }

    let url = `${BaseUrl}/changerequests/?page=${pageNum}`;

    // Add this block to handle ID filter
    if (selectedFilter.id) {
      url += `&id=${encodeURIComponent(selectedFilter.id)}`;
    } else if (searchQueryGet) {
      // Use search parameter for all search queries
      url += `&search=${encodeURIComponent(searchQueryGet)}`;
    }

    if (filters) url += `&${filters}`;
    if (ordering) url += `&${ordering}`;

    url = url.replace(/&undefined=[^&]*/g, '');

    // Check if the URL is the same as the last request
    const lastRequestUrl = localStorage.getItem('lastRequestUrl');
    if (url === lastRequestUrl) {
      console.log('URL hasn\'t changed, skipping request');
      setIsLoading(false);
      setLoadingData(false);
      return;
    }

    // Check if the requested page is valid
    if (pageNum !== 1 && pageNum > nextPageToLoad) {
      console.log(`Invalid page request. Expected ${nextPageToLoad}, got ${pageNum}`);
      setIsLoading(false);
      setLoadingData(false);
      return;
    }

    // Save the new URL to localStorage
    localStorage.setItem('lastRequestUrl', url);

    console.log("Request URL:", url);

    fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setTotal(result.total_pages);
          if (!result || !Array.isArray(result.results)) {
            console.error("Invalid API response:", result);
            setLocationInfo([]);
            setHasMore(false);
            setLoadingData(false);
            setIsLoading(false);
            return;
          }
          setCurrentPage(pageNum);
          setLastLoadedPage(pageNum); // Set the last loaded page
          setNextPageToLoad(pageNum + 1);
          if (pageNum === 1) {
            setLocationInfo(result.results);
          } else {
            setLocationInfo(prevInfo => {
              const newIds = new Set(result.results.map(item => item.id));
              const filteredPrevInfo = prevInfo.filter(item => !newIds.has(item.id));
              return [...filteredPrevInfo, ...result.results];
            });
          }
          setHasMore(result.next !== null);
          setLoadingData(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLocationInfo([]);
          setHasMore(false);
          setLoadingData(false);
          setIsLoading(false);
        });
  }, [isLoading, requestOptions, selectedFilter, sortedColumn, sortDirection, nextPageToLoad]);

  useEffect(() => {
    params === null && fetch(`${BaseUrl}/changerequests/filterOptions/`, requestOptions)
        .then((response) => response.json())
        .then((result) => {

          setParams(result)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);

        })

  }, [selectedFilter])

  const deleteData = (id) => {
    setLoadingData(true);
    setTotal(0)
    fetch(`${BaseUrl}/changerequests/${id}/`, {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify({ "status": "Отменен" })

    })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to delete item with id: ${id}`);
          }
          console.log(`Item with id: ${id} deleted successfully`);
          loadData(1);
          setLoadingData(false);
        })
        .then(() => {
          dateFetch(`/finalrequests/`, requestOptionsChangeStatus);
        })
        .catch((error) => {
          console.error("Error deleting data:", error);
          setLoadingData(false);
        });
  };
  const loadMore = useCallback(() => {
    if (hasMore && !loadingData && !isLoading) {
      loadData( nextPageToLoad);
    }
  }, [hasMore, loadingData, isLoading, nextPageToLoad, loadData ,searchQueryGet]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        loadMore();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);

  }, [loadMore, startF,selectedFilter]);


  myHeaders.append("Content-Type", "application/json");



  const handleDelet = useCallback((status) => {
    if (!pinkLine || !newID || !status) {
      console.error("Missing required parameters.");
      return;
    }

    const bodyData = {
      "status": status
    };

    if (status === 'Скорректировано') {
      bodyData.supervisor_approved_value = superviserCenceled[0].supervisor_approved_value;
      bodyData.supervisor_comment = superviserCenceled[1].supervisor_comment;
    }

    const requestOptionsDelete = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify(bodyData)
    };
    setTotal(0)
    fetch(`${BaseUrl}/changerequests/${pinkLine}/`, requestOptionsDelete)

        .then((response) => {
          if (response.ok) {

            return response.text();
          }
          throw new Error('Network response was not ok.');
        })
        .then((result) => {
          setLocationInfo(result);
          setStart(false);
          setStart(true);
          dateFetch(`/finalrequests/`, requestOptionsChangeStatus);
          closeModal('modalWindow3');
          closeModal('modalWindow4');
          openModal('modalWindow5');
          closeModal('modalWindow6');
          closeModal('modalWindow7');
          setStart(!start);
          loadData(1);
        })
        .catch((error) => console.error(error));

  }, [pinkLine, newID, setStart, dateFetch, superviserCenceled, myHeaders, start]);


  useEffect(() => {
    let newUrl = selectedFilter.Статус ? 'status=' + encodeURIComponent(selectedFilter.Статус)
        : selectedFilter.Автор ? 'kam_user=' + encodeURIComponent(selectedFilter.Автор)
            : selectedFilter.Категория ? 'category=' + encodeURIComponent(selectedFilter.Категория)
                : selectedFilter.РЦ ? "warehouse=" + encodeURIComponent(selectedFilter.РЦ)
                    : selectedFilter.id ? "id=" + encodeURIComponent(selectedFilter.id)
                        : null;

    const keys = Object.keys(selectedFilter);
    if (keys.length > 1) {
      if (selectedFilter.Статус) newUrl += '&status=' + encodeURIComponent(selectedFilter.Статус);
      if (selectedFilter.Автор) newUrl += '&kam_user=' + encodeURIComponent(selectedFilter.Автор);
      if (selectedFilter.Категория) newUrl += '&category=' + encodeURIComponent(selectedFilter.Категория);
      if (selectedFilter.РЦ) newUrl += '&warehouse=' + encodeURIComponent(selectedFilter.РЦ);
      if (selectedFilter.id) newUrl += '&id=' + encodeURIComponent(selectedFilter.id);
    }

    if (selectedFilter.ordering) {
      newUrl = "ordering=" + selectedFilter.ordering;
    }
    if (newUrl) {
      setTotal(0)
    } else loadData(1)
  }, [selectedFilter]);


  const role = JSON.parse(localStorage.getItem('role'));

  const handleApproveSelected = (status) => {
    if (selectedIds.length === 0) {
      console.error("No ids selected.");
      return;
    }

    const requestOptionsApproveSelected = {
      method: "PATCH",
      headers: myHeaders,
      body: JSON.stringify({
        id: selectedIds,
        approved_by: status,
      })
    };
    setTotal(0)
    fetch(`${BaseUrl}/changerequests/bulk-update/`, requestOptionsApproveSelected)
        .then((response) => {
          if (response.ok) {
            return response.text();
          }
          throw new Error('Network response was not ok.');
        })
        .then(() => {
          loadData(1);
          setSelectedIds([]);
          openModal('modalWindow5');
        })
        .catch((error) => console.error(error));
  };

  const handleSortByStatus = () => {
    setSortedColumn("status");
    setSortDirection(prevDirection => prevDirection === 'desc' ? 'asc' : 'desc');
  };

  const handleSortByDate = () => {
    setSortedColumn("created_at");
    setSortDirection(prevDirection => prevDirection === 'desc' ? 'asc' : 'desc');
  };

  const openSupervisorWew = (e, index) => {
    setPinkLine(e.id);
    if (role === "supervisor") {
      openModal('modalWindow6');
      setDateIndex(index);
    }
  };
  useEffect(() => {
    setNextPageToLoad(1);
    setLocationInfo([]);
    setLoadingData(true);
    loadData(1);
  }, [debouncedSearchQuery, sortedColumn, sortDirection, selectedFilter]);

  const [filterWindow, setFilterWindow] = useState(false);

  // Create a debounced function
  const debouncedSearch = useCallback(
      debounce((query) => {
        setDebouncedSearchQuery(query);
      }, 1000),
      []
  );

  const hasSelectedFilters = Object.values(selectedFilter).some(value => value !== undefined && value !== '');

  return (
      <>
        <Header />
        {modalAplication}
        <div className={style.container && stulePage.container}>
          <div className={stulePage.fixBlock}>
            <div className={stulePage.searchBlock}>
              <input
                  type="search"
                  placeholder={'Поиск'}
                  value={searchQueryGet}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setNextPageToLoad(1)
                    setSearchQueryGet(newValue);
                    debouncedSearch(newValue);
                  }}
              />
              {role !== "admin" && role !== "supervisor" ? (
                  <button className={stulePage.modal} onClick={() => openModal('modalWindow')}>
                    Новая заявка
                  </button>
              ) : null}
              <button
                  className={`${stulePage.filterBotton} ${hasSelectedFilters ? stulePage.filterBottonActive : ''}`}
                  onClick={() => setFilterWindow(!filterWindow)}
              >
                <img src={filterIcon} alt="" />
              </button>
              {filterWindow && (
                  <Filter
                      serStartF={serStartF}
                      srtStartFilter={srtStartFilter}
                      filterOptions={params}
                      onChangeFilter={setSelectedFilter}
                      selectedFilter={selectedFilter}
                      setFilterWindow={setFilterWindow}
                  />
              )}
              <button className={style.filter}>
                <img src={sortIcon} alt="" />
                <ul className={style.filterContainer}>
                  <li onClick={handleSortByDate}>
                    по дате {sortedColumn === "created_at" && (sortDirection === 'desc' ? '▼' : '▲')}
                  </li>
                  <li onClick={handleSortByStatus}>
                    по статусу {sortedColumn === "status" && (sortDirection === 'desc' ? '' : '◀')}
                  </li>
                </ul>
              </button>
            </div>
          </div>
          <div className={stulePage.divBlock}>
            <table className={stulePage.table}>
              <tbody>
              <tr className={stulePage.tableTitles}>
                {role === "supervisor" ?
                    <td className={stulePage.input}></td> : null}
                <td className={stulePage.input}>Номер <br /> заявки</td>
                <td className={stulePage.input}>Автор</td>
                <td className={stulePage.input}>Белый <br /> список</td>
                <td className={stulePage.input}>Катеория</td>
                <td className={stulePage.input}>РЦ</td>
                <td className={`${stulePage.input} ${stulePage.cuant}`}>Квант</td>
                <td className={`${stulePage.input} ${stulePage.Cause}`}>Причина</td>
                {role !== "supervisor" &&
                    <td className={`${stulePage.input} ${stulePage.Cause}`}>Причина супервайзера</td>}
                <td className={`${stulePage.input} `}>Квант супервайзера</td>
                <td className={`${stulePage.input} ${stulePage.cuantX}`}>Текущ <br /> квант</td>
                {role !== "supervisor" && <td className={stulePage.input}>Max <br /> квант</td>}
                <td className={stulePage.input}>Дельта, %</td>
                <td className={stulePage.input}>Дельа, шт</td>
                <td className={stulePage.input}> Продажи 30 дней</td>
                <td className={stulePage.input}>Прогно</td>
                {role !== "supervisor" && <td className={stulePage.input}>Остаток</td>}
                <td className={stulePage.input}>Мерант</td>
                <td className={stulePage.input}>Статус</td>
                <td className={stulePage.input}>Срок кванта</td>
                <td className={stulePage.input}>Дата / время</td>
                {role === "admin" && <td></td>}
                {role === "KAM" ? <td></td> : null}
              </tr>
              {Array.isArray(locationInfo) && locationInfo.length > 0 ? (
                  locationInfo.map((e, index) => (
                      <tr key={e.id} className={pinkLine === e.id ? "pink" : ''}
                      >

                        {role === "supervisor" ?
                            <td className={stulePage.input}>
                              <input
                                  className={stulePage.checkbox}
                                  type={"checkbox"}
                                  checked={selectedIds.includes(e.id)}
                                  onChange={(event) => {
                                    const isChecked = event.target.checked;
                                    if (isChecked) {
                                      setSelectedIds([...selectedIds, e.id]);
                                    } else {
                                      setSelectedIds(selectedIds.filter(id => id !== e.id));
                                    }
                                    setPinkLine(e.id);
                                  }}
                              />
                            </td> : null}
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.id}</p></td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}>
                          <p>{e.kam_user_detail.first_name} {e.kam_user_detail.last_name} {e.kam_user_detail.patronymic}</p>
                        </td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}>
                          {e.merchant_flag && <img src={draftx} />}
                        </td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}>
                          <p>{e.category_detail && e.category_detail.ML6 ? e.category_detail.ML6 : "-"}</p>
                        </td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.warehouse_detail.location}</p></td>
                        <td className={`${stulePage.input} ${stulePage.cuant}`} onClick={() => openSupervisorWew(e, index)}><p>{e.new_value}</p>
                        </td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.reason}</p></td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.supervisor_comment || '-'}</p></td>
                        {role !== "supervisor" &&
                            <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.supervisor_approved_value || '-'}</p>
                            </td>}
                        <td className={`${stulePage.input} ${stulePage.cuantX}`} onClick={() => openSupervisorWew(e, index)}>
                          <p>{e.current_quantity || "-"}</p>
                        </td>
                        {role !== "supervisor" &&
                            <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.max_requested_quantity}</p></td>}
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}>
                          <p>{e.delta && e.delta.toFixed(1)}</p>
                        </td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}>
                          <p>{e.change} </p>
                        </td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.sales_last_30_days}</p></td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.sales_forecast.toFixed(1) || '-'}</p></td>
                        {role !== "supervisor" &&
                            <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.latest_remains || '-'}</p></td>}
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.merchant_id || '-'}</p></td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}>
                          {status && status === e.id && selectedValues[e.id] ? (
                              <td className={stulePage.selectOption} onClick={() => openSupervisorWew(e, index)}>
                                <img
                                    src={
                                      selectedValues[e?.id] === "Одобено" ? approved :
                                          selectedValues[e.id] === "на рассмотрении" ? under_review :
                                              selectedValues[e.id] === "Черновик" ? draft :
                                                  selectedValues[e.id] === "Отклонено" ? rejected :
                                                      selectedValues[e.id] === "Оменен" ? rejected :
                                                          selectedValues[e.id] === "В Обработке" ? InProcessing :
                                                              selectedValues[e.id] === "Загружено" ? Uploaded :
                                                                  selectedValues[e.id] === "Скорректировано" ? propertyIcon :

                                                                      null
                                    }
                                    alt="ggg"
                                />
                              </td>
                          ) : (
                              !status || status !== e.id ? (
                                  <td className={stulePage.selectOption} onClick={() => openSupervisorWew(e, index)}>
                                    {
                                      e.status === "На рассмотрении" ?
                                          <img className={'under_review'}
                                               src={InProcessing} alt="ggg"
                                               title="2 - На рассмотрении" /> :
                                          e.status === "Черновик" ?
                                              <img className={'under_review'} src={draft}
                                                   alt="ggg"
                                                   title="1 - Черновик"
                                                   onClick={() => {
                                                     setApdateID(e);
                                                     openModal('modalWindow');
                                                   }} /> :
                                              e.status === "Одобрено" ?
                                                  <img className={'under_review'}
                                                       src={approved} alt="ggg"
                                                       title="3 -Одобрено" /> :
                                                  e.status === "Отклонено" || e.status === "Отменен" ?
                                                      <img className={'under_review'}
                                                           src={rejected} alt="ggg"
                                                           title="4 - Отклонено" /> :
                                                      e.status === "В Обработке" ?
                                                          <img className={'under_review'}
                                                               src={under_review}
                                                               alt="ggg"
                                                               title="5 - В Обработке" /> :
                                                          e.status === "Загружено" ?
                                                              <img
                                                                  className={'under_review'}
                                                                  src={Uploaded} alt="ggg"
                                                                  title="6 - Загружено" /> :
                                                              e.status === "Скорректировано" ?
                                                                  <img
                                                                      className={'under_review'}
                                                                      src={propertyIcon} alt="ggg"
                                                                      title="7 - Скорректировано" /> :
                                                                  null
                                    }
                                  </td>
                              ) : null
                          )}
                        </td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}><p>{e.date_new_value || '-'}</p></td>
                        <td className={stulePage.input} onClick={() => openSupervisorWew(e, index)}>
                          <p>{e.created_at.slice(0, 10) + " " + e.created_at.slice(11, 16)}</p></td>
                        {role === "KAM" ? <td>{e.status === "Черновик" ? <button onClick={() => {
                              setNewID(e.id);
                              openModal('modalWindow2');
                            }} className={"sand_btn save_button"}>Отправить</button> :
                            <button className={" sand_btn default"}>Отправлено</button>}</td> : null}
                        {role === "admin" && <td onClick={() => {
                          setSpred(!spred)
                          setNewID(e.id)
                        }}>
                          <h2 style={{ textAlign: 'center', cursor: "pointer" }} >{spred && newID === e.id ? "x" : '...'}</h2>
                          {spred && newID === e.id && <div className={'spredBlock'}>
                            <button className={'add_btn rose_btn'} onClick={() => {

                              openModal('modalWindow8')
                            }}>Отменить
                            </button>
                          </div>}

                        </td>}
                      </tr>
                  ))
              ) : (
                  <tr>
                    <td colSpan="21">Данные отсутствуют или загружаются</td>
                  </tr>
              )}
              </tbody>
            </table>
          </div>
          {role === "supervisor" && selectedIds.length ?
              <div className='flex_buttons'>
                <button className={"add_btn green_btn"} onClick={() => {
                  setApproved_by('Одобрено');
                  handleApproveSelected('Одобрено');
                }}>Одобрить выбранные
                </button>
                <button className={"sand_btn red_btn"} onClick={() => {
                  setApproved_by('Отклонено');
                  handleApproveSelected('Отклонено');
                }}>Отклонить выбранные
                </button>
                <button className={"add_btn "} onClick={() => setSelectedIds([])}>Сбросить выбранные
                </button>
              </div>
              : null}
        </div>
        {modals.modalWindow8 && (
            <ModalDeleteApplication
                handleDelet={deleteData}
                setModalWindow={() => {
                  closeModal('modalWindow8')
                  loadData(1);
                }}
                id={newID}
            />
        )}
        {modals.modalWindow5 && (
            <ResponseOk setResponseOn={() => closeModal('modalWindow5')} />
        )}
        {modals.modalWindow && (
            <NewApplication
                setModalWindow={() => {
                  closeModal('modalWindow')
                  loadData(1);
                }}
                date={locationInfo}
                setStart={setStart}
                setModalAplication={setModalAplication}
                apdateID={apdateID}
                setApdateID={setApdateID}
            />
        )}
        {modals.modalWindow2 && (
            <KamModal
                handleDelet={handleDelet}
                setModalWindow={() => {
                  closeModal('modalWindow2')
                  loadData(1);
                }}
                id={newID}
                setModalAplication={setModalAplication}
                apdateID={apdateID}
                setApdateID={setApdateID}
            />
        )}
        {modals.modalWindow6 && (
            <NewApplication
                setModalWindow={() => {
                  closeModal('modalWindow6')
                  loadData(1);
                }}
                setModalWindow3={() => openModal('modalWindow3')}
                setModalWindow4={() => openModal('modalWindow4')}
                setModalWindow7={() => openModal('modalWindow7')}
                setNewID={setNewID}
                date={locationInfo}
                setStart={setStart}
                setModalAplication={setModalAplication}
                apdateID={apdateID}
                setApdateID={setApdateID}
                wew={[locationInfo[dateIndex]]}
            />
        )}
        {modals.modalWindow3 && (
            <SupervisorModal
                handleDelet={handleDelet}
                setModalWindow={() => {
                  closeModal('modalWindow3');
                  loadData(1);
                }}
                id={pinkLine}
                status={'Одобрено'}
                setSuperviserCenceled={setSuperviserCenceled}
            />
        )}
        {modals.modalWindow7 && (
            <SupervisorModal
                handleDelet={handleDelet}
                setModalWindow={() => {
                  closeModal('modalWindow7');
                  loadData(1);
                }}
                id={pinkLine}
                status={'Отклонено'}
                status2={'Отклонено'}
                setSuperviserCenceled={setSuperviserCenceled}
            />
        )}
        {modals.modalWindow4 && (
            <SupervisorModal
                handleDelet={handleDelet}
                setModalWindow={() => {
                  closeModal('modalWindow4');
                  loadData(1);
                }}
                id={pinkLine}
                setStatus={setStatus}
                status={'Скорректировано'}
                setSuperviserCenceled={setSuperviserCenceled}
            />
        )}
      </>
  );
}
