import React, {useEffect, useState} from "react";
import style from "../DistributionCenter/DistributionCenter.module.css";
import {dateFetch, dateFetch2} from "../../../../fetch";
import ResponseOk from "../../../../components/modalWindos/responseOK/ResponseOk";
import loadingIMG from "../../../../imgs/icons/loader.webp";
import AddGroup from "../../../../components/modalWindos/addGroup/Index";

export default function Grups(){

    const [locationInfo,setLocationInfo] = useState([])
    const [newValue,setNewValue] = useState('')
    const [responseOn,setResponseOn] = useState(false)
    const [loading,setLoading] = useState(true);
    const [gropError, setGropError] = useState('');
    const [modalWindow, setModalWindow] = useState(false);
    const [editableIndex, setEditableIndex] = useState(null); // Keep track of which item is being edited

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + JSON.parse(localStorage.getItem("token") ));

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    const url = "/groups/";

    useEffect(() => {
        dateFetch(url,requestOptions,setLocationInfo)
        setLoading(false)
    }, []);

    myHeaders.append("Content-Type", "application/json");

    function handleEdit(index) {
        setEditableIndex(index);
    }

    function handleInputChange(e, index) {
        const updatedLocationInfo = [...locationInfo];
        updatedLocationInfo[index].name = e.target.value;
        setLocationInfo(updatedLocationInfo);
    }

    function handleSave(index) {
        const updatedLocation = locationInfo[index];
        const updatedRequestOptionsPut = {
            method: "PUT",
            headers: myHeaders,
            body: JSON.stringify(updatedLocation),
        };

        dateFetch2(url + updatedLocation.id + "/", updatedRequestOptionsPut)
            .then(() => {
                setResponseOn(true);
                setGropError('');
                setEditableIndex(null);
            })
            .catch(error => {
                console.error('Error:', error);
                setGropError("Ошибка при сохранении изменений.");
            });
    }

    function handleSubmit(e) {
        e.preventDefault();

        const newCategory = {
            "name": newValue,
        };

        const updatedRequestOptionsPost = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify(newCategory),
        };

        dateFetch2(url, updatedRequestOptionsPost)
            .then(() => {
                setResponseOn(true)
                setGropError('')
                setNewValue('')
                return dateFetch(url, requestOptions, setLocationInfo);
            })
            .catch(error => {
                console.error('Error:', error);
                setGropError("Группа с таким name уже существует.")
            });
        setModalWindow(false)
    }

    return(
        <>
            {responseOn? <ResponseOk setResponseOn={setResponseOn}/>:null}

            <h2>Группы</h2>
            <div className={style.table}>
                <div  className={style.titles}>
                    <p>Название</p>
                </div>
                <table className={style.container}>

                    {loading ? (
                        <div className={'ladingBlock'}>
                            <img src={loadingIMG} alt=""/>
                        </div>

                    ) :locationInfo.length?locationInfo.map((e, index) => {

                        return (
                            <tr key={index}>
                                <td>
                                    {editableIndex === index ? (
                                        <input
                                            className={style.input}
                                            type="text"
                                            value={e.name}
                                            onChange={(event) => handleInputChange(event, index)}
                                        />
                                    ) : (
                                        <input
                                            className={style.input}
                                            type="text"
                                            value={e.name}
                                           readOnly
                                        />
                                    )}
                                </td>
                                <td className={'chage_Btn'}>
                                    {editableIndex === index ? (
                                        <button className={'add_btn'}
                                                onClick={() => handleSave(index)}>Сохранить</button>
                                    ) : (
                                        <button className={'sand_btn'} onClick={() => handleEdit(index)}>Изменить</button>
                                    )}
                                </td>
                            </tr>
                        );
                    }):null}
                </table>
            </div>
            <div className={style.containerGroupFetch}>
                <div className={`${style.inputsBlock}`}>
                    <input className={style.input} placeholder={"напишите название группы"} onChange={(e)=>setNewValue(e.target.value)} value={newValue} type="text"/>

                </div>
                {gropError ? <p className={style.error}>{gropError} </p>:null}
                <div className={style.btns}>
                    <button className={newValue?' add_btn':'sand_btn'} onClick={()=>setModalWindow(newValue?true:false)} >Добавить</button>
                </div>
            </div>
            {modalWindow?<AddGroup handle={handleSubmit} setModalWindow={setModalWindow}/>:<></>}
        </>
    )
}
