import React, { useEffect, useState } from "react";
import style from './Index.module.css';
import closeIcon from './../../../imgs/icons/closeIcon.png';
import { dateFetch } from "../../../fetch";
import Select from 'react-select';
import AplicationModal from "../AplicationsModal/AplicationModal";
import loaderIMG from "../../../imgs/icons/loader.webp";
import AplicationErrorModal from "../AplicationsModal/AplicationErrorModal";
import { BaseUrl, wssUrl, wssUrl2 } from "../../../url";
import calendarIcon from '../../../imgs/calendar-check.png';
import Calendar from "react-calendar";
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import vector4 from '../../..//imgs/Vector4.png';
import DraftIcon from '../../../imgs/icons/Property 1=Default0.png';
import Reviver from '../../../imgs/icons/Property 1=Default2.png';

function NewApplication({
                          setModalWindow,
                          date,
                          setStart,
                          setModalAplication,
                          apdateID,
                          setApdateID,
                          wew,
                          setNewID,
                          setModalWindow3,
                          setModalWindow4,
                          setModalWindow7
                        }) {
  const [locationInfo, setLocationInfo] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [loader, setLoader] = useState(false);
  const [categoryID, setCategoryID] = useState(null);
  const [warehouseID, setWarehouseID] = useState(null);
  const [newValue, setNewValue] = useState(apdateID.new_value || '');
  const [wssDate, setWssDate] = useState({});
  const [wssDate2, setWssDate2] = useState({});
  const [isChecked, setIsChecked] = useState(apdateID.seasonality || false);
  const [isChecked2, setIsChecked2] = useState(apdateID.promo || false);
  const [modifiedFields, setModifiedFields] = useState({});
  const [merchantid, setMerchantid] = useState();
  const [merchant, setMerchant] = useState(false); // Initialize merchant before use
  const [calendarValue, setCalendarValue] = useState(null);
  const [calendarWindow, setCalendarWindow] = useState(false);
  const [status, setStatus] = useState({});
  const [userWhiteId, whiteID] = useState();

  const [userEH, setUserWH] = useState([]);

  const [locationInfo2, setLocationInfo2] = useState([]);
  const [users3, setUsers3] = useState(false);
  const [users22, setUsers22] = useState([]);
  const [groupId, setGroupID] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const [submissionError, setSubmissionError] = useState({
    category: false,
    warehouse: false,
    newValue: false,
    reason: false,
    merchant_id: false,
    date_new_value: false,
    past_date_error: false,
    reason_only_spaces: false,
    approver: false ,
    new_value: false
  });

  useEffect(() => {
    merchant && setNewValue(9999);
    setSubmissionError({
      newValue: false,
      reason: false,
      merchant_id: false,
      date_new_value: false,
      past_date_error: false,
      approver: false // Reset approver error state
    });
  }, []);

  const [priceSegment, setPriceSegment] = useState("");
  const [formData, setFormData] = useState({
    category: apdateID.category || "",
    new_value: merchant ? newValue || '1000' : apdateID.new_value || '',
    warehouse: apdateID.warehouse || "",
    reason: apdateID.reason || "",
    merchant_id: apdateID.merchant_id || "",
    price_segment: priceSegment.value || "",
    seasonality: isChecked,
    promo: isChecked2,
    merchant_flag: merchant,
    kam_user: JSON.parse(localStorage.getItem('userId')),
    date_new_value: calendarValue ? `${calendarValue.getFullYear()}-${calendarValue.getMonth() + 1}-${calendarValue.getDate()}` : null,
    approver: selectedOption ? selectedOption.value : ""
  });

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + JSON.parse(localStorage.getItem("token")));
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
  };
  setTimeout(() => loader ? setLoader(false) : null, 5000);

  useEffect(() => {
    dateFetch("/warehouses/", requestOptions, setLocationInfo);
    loadCategories(1);
  }, []);

  const loadCategories = async (pageNumber, search = '') => {
    try {
      const response = await fetch(`${BaseUrl}/categories/?page=${pageNumber}&search=${search}`, requestOptions);
      const data = await response.json();
      if (data.results.length > 0) {
        setCategories(prevCategories => [...prevCategories, ...data.results]);
        setHasMore(!!data.next);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error loading categories:', error);
    }
  };

  const handleScroll = (event) => {
    const { target } = event;
    if (target.scrollHeight - target.scrollTop === target.clientHeight && hasMore) {
      setPage(prevPage => prevPage + 1);
      loadCategories(page + 1, searchTerm);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCategories([]);
    // setPage(1);
    loadCategories(1, value);
  };

  const handleChange = (name, value) => {
    const today = new Date();
    const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 30);
    if (name === 'date_new_value') {
      const selectedDate = new Date(value);
      if (selectedDate < today || selectedDate > maxDate) {
        setSubmissionError({ ...submissionError, past_date_error: true });
        return;
      } else {
        setSubmissionError({ ...submissionError, past_date_error: false });
      }
    }
    setFormData({ ...formData, [name]: value, date_new_value: calendarValue ? `${calendarValue.getFullYear()}-${calendarValue.getMonth() + 1}-${calendarValue.getDate()}` : null });
    setModifiedFields({ ...modifiedFields, [name]: value, date_new_value: calendarValue ? `${calendarValue.getFullYear()}-${calendarValue.getMonth() + 1}-${calendarValue.getDate()}` : null });
    setSubmissionError({ ...submissionError, [name]: false, past_date_error: false, reason_only_spaces: false });
  };
  useEffect(() => {
    console.log(userWhiteId, "2222222");
  }, [userWhiteId]);

  useEffect(() => {
    if (merchant) {
      const today = new Date();
      const newDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);
      handleChange("date_new_value", newDate);
      setCalendarValue(newDate);
    }
  }, [merchant]);

  const handleChangePatch = async (e) => {
    e.preventDefault();

    if (formData.reason.trim() === '') {
      setSubmissionError({ ...submissionError, reason_only_spaces: true });
      return;
    }

    const response = await fetch(`${BaseUrl}/changerequests/${apdateID.id}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
      },
      body: JSON.stringify(modifiedFields)
    });

    if (!response.ok) {
      setLoader(true);
      setModalAplication(<AplicationErrorModal text={"что-то прошло не так"} />);
      setApdateID('');
      setSubmissionError(true);
    } else {
      setLoader(true);
      setModalWindow(false);
      setModalAplication(<AplicationModal text={"успешно"} />);
      setApdateID('');
      setSubmissionError(false);
      setStart(true);
    }
  };

  const wss2 = (categoryID, warehouseID, newValue, merchantid) => {
    if (categoryID && warehouseID && newValue) {
      const ws = new WebSocket(wssUrl2);

      ws.onopen = () => {
        console.log({
          category_id: !merchant && categoryID,
          warehouse_id: warehouseID,
          new_value: newValue,
          merchant_id: +merchantid,
          seasonality: isChecked,
          promo: isChecked2,
          price_segment: priceSegment.value || ""
        });
        ws.send(JSON.stringify({
          category_id: !merchant && categoryID,
          warehouse_id: warehouseID,
          new_value: newValue,
          merchant_id: +merchantid,
          seasonality: isChecked,
          promo: isChecked2,
          price_segment: priceSegment.value || ""
        }));
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setStatus(data.status_lights);
        console.log(data);
        setWssDate2({
          Дельта: data.delta.toFixed(1) + " %",
          Изменение: data.change + " шт.",
          sales_forecast: data.sales_forecast.toFixed(1),
          p1: data.sales_data.current_rc?.p1.pokd !== "Нет данных" ? data.sales_data.current_rc?.p1.pokd : '',
          p2: data.sales_data.current_rc?.p2.pokd !== "Нет данных" ? data.sales_data.current_rc?.p2.pokd : "",
          rest: data.sales_data.current_rc?.p1.rest !== "Нет данных" ? data.sales_data.current_rc?.p1.rest : "",
          rest2: data.sales_data.current_rc?.p2.rest !== "Нет данных" ? data.sales_data.current_rc?.p2.rest : "",
          P1rest: data.sales_data.current_rc?.p1.sales !== "Нет данных" ? data.sales_data.current_rc?.p1.sales : "",
          P2rest: data.sales_data.current_rc?.p2.sales !== "Нет данных" ? data.sales_data.current_rc?.p2.sales : "",
          moscow_1p: data.sales_data.moscow?.p1.pokd !== "Нет данных" ? data.sales_data.moscow?.p1.pokd : "",
          moscow_2p: data.sales_data.moscow?.p2.pokd !== "Нет данных" ? data.sales_data.moscow?.p2.pokd : "",
          regions_1p: data.sales_data.regions?.p1.pokd !== "Нет данных" ? data.sales_data.regions?.p1.pokd : "",
          regions_2p: data.sales_data.regions?.p2.pokd !== "Нет данных" ? data.sales_data.regions?.p2.pokd : "",
          P1MoscowRest: data.sales_data.moscow?.p1.sales !== "Нет данных" ? data.sales_data.moscow?.p1.sales : "",
          P2MoscowRest: data.sales_data.moscow?.p2.sales !== "Нет данных" ? data.sales_data.moscow?.p2.sales : "",
          P1regionsRest: data.sales_data.regions?.p1.sales !== "Нет данных" ? data.sales_data.regions?.p1.sales : "",
          P2regionsRest: data.sales_data.regions?.p2.sales !== "Нет данных" ? data.sales_data.regions?.p2.sales : "",
        });
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = (event) => {
        console.log('WebSocket connection closed unexpectedly:', event);
      };
    }
  };

  useEffect(() => {
    wss2(categoryID, warehouseID, newValue, merchantid);
  }, [categoryID, warehouseID, newValue, merchantid, priceSegment, isChecked2, isChecked]);

  const wss = (categoryID, warehouseID, merchantid) => {
    if (categoryID && warehouseID) {
      const ws = new WebSocket(wssUrl);

      ws.onopen = () => {
        ws.send(JSON.stringify({ category_id: categoryID, warehouse_id: warehouseID, merchant_id: merchantid }));
      };

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        setWssDate({
          Текущий_Квант: +data.current_quantity,
          Мах_запрошено: +data.max_requested,
          summ: data.sales_last_30_days.toFixed(1) + " шт.",
          latest_remains: data.latest_remains,
        });
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      ws.onclose = (event) => {
        console.log('WebSocket connection closed unexpectedly:', event);
      };
    }
  };

  useEffect(() => {
    wss(categoryID, warehouseID, merchantid);
  }, [categoryID, warehouseID, merchantid]);

  useEffect(() => {
    setFormData({
      ...formData,
      category: categoryID || "",
      warehouse: warehouseID || "",
      merchant_id: merchantid || "",
      date_new_value: calendarValue ? `${calendarValue.getFullYear()}-${calendarValue.getMonth() + 1}-${calendarValue.getDate()}` : null,
      approver: selectedOption ? selectedOption.value : ""
    });
  }, [categoryID, warehouseID, newValue, merchantid, calendarValue, isChecked, isChecked2, priceSegment, merchant, selectedOption]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    const today = new Date();
    const maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 30);
    if (calendarValue && (new Date(calendarValue) < today || new Date(calendarValue) > maxDate)) {
      setSubmissionError({ ...submissionError, past_date_error: true });
      return;
    } else {
      setSubmissionError({ ...submissionError, past_date_error: false });
    }

    const emptyFields = Object.entries(formData).filter(([key, value]) => {
      if (key === 'reason') {
        return !value || value.trim() === '';
      }
      return !value && (!merchant && key === 'new_value' || !merchant && key === 'category' || key === 'warehouse' || merchant && key === 'merchant_id' || merchant && key === 'date_new_value' || merchant && key === 'approver');
    });

    if (emptyFields.length > 0) {
      const newSubmissionError = emptyFields.reduce((acc, [key]) => {
        if (key === 'reason' && formData.reason.trim() === '') {
          acc.reason_only_spaces = true;
        } else {
          acc[key] = true;
        }
        return acc;
      }, {});
      setSubmissionError({ ...submissionError, ...newSubmissionError });
      return;
    }

    const response = await fetch(`${BaseUrl}/changerequests/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
      },
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      setLoader(true);
      setSubmissionError(true);
      setModalAplication(<AplicationErrorModal text={"что-то прошло не так"} />);
    } else {
      setSubmissionError(false);
      setStart(true);
      setModalWindow(false);
      setModalAplication(<AplicationModal text={"успешно"} />);
      setLoader(false);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${BaseUrl}/changerequests/${apdateID.id}/`, {
        method: "DELETE",
        headers: {
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
        }
      });

      if (!response.ok) {
        console.error("Ошибка при удалении элемента");
        return;
      }
      setSubmissionError(false);
      setStart(true);
      setModalWindow(false);
      setApdateID('');
      setModalAplication(<AplicationModal text={"успешно"} />);
      setLoader(false);

      console.log("Элемент успешно удален");
    } catch (error) {
      console.error("Произошла ошибка:", error);
    }
  };

  const erorText = '* данное поле является обязательным для заполнения';
  const allWarehousesIndex = locationInfo.findIndex(location => location.location === 'Все РЦ');
  const allWarehousesOption = allWarehousesIndex !== -1 ? locationInfo[allWarehousesIndex] : null;

  const finalOptions = allWarehousesOption
      ? [allWarehousesOption, ...locationInfo.filter(option => option.location !== 'Все РЦ')]
      : locationInfo;

  const StyledCalendar = styled(Calendar)`
        width: 300px;
        border: 1px solid rgba(235, 235, 235, 0.89);
        border-radius: 5px;
        padding: 2px;
        font-family: Arial, Helvetica, sans-serif;
        margin-top: -14px;

        .react-calendar__tile {
            background: #fff;
            text-align: center;
            padding: 2px 4px;
        }
        .react-calendar__tile--hover {
            background: #8809dc;
        }
        .react-calendar__tile--active {
            background: #8809dc;
            color: white;
            border-radius: 4px;
        }

        .react-calendar__tile--now {
            color: #FF3797;
        }
        .react-calendar__navigation {
            height: 15px;
        }
        .react-calendar__navigation button {
            color: #333;
            min-width: 22px;
            height: 20px;
        }

        .react-calendar__month-view__weekdays {
            text-transform: uppercase;
            font-weight: bold;
        }
        .react-calendar__month-view__days__day--weekend {
            color: #FF3797;
        }
        .react-calendar__month-view__weekdays__weekday--weekend {
            color: #FF3797;
        }
        .jrBycB .react-calendar__navigation:hover button {
            color: #FF3797;
            min-width: 22px;
        }
        .react-calendar {
            width: 100%;
        }
    `;

  useEffect(() => {
    handleChange("category_id", merchant && '')
    handleChange("new_value", merchant ? newValue || '1000' :newValue || '')

  }, [merchant]);

  useEffect(() => {
    if (merchant && newValue >= 9999) {
      setNewValue(9999)
    }
  }, [newValue]);

  const today = new Date();
  const NewDatan = today.toString();

  const requestOptions2 = {
    method: "GET",
    headers: myHeaders,
  };

  useEffect(() => {
    dateFetch("/api/users/", requestOptions2, setUsers22);
    fetch(`${BaseUrl}/groups/`, requestOptions2)
        .then(response => response.json())
        .then(data => setLocationInfo2(data))
        .catch(error => console.error("Error fetching location info:", error));
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let usersData = [];
        for (const x of locationInfo2) {
          if (x.name === 'Согласование белого списка') {
            setGroupID(x.id);
            const response = await fetch(`${BaseUrl}/employeegroups/`, requestOptions2);
            const userData = await response.json();
            if (Array.isArray(userData)) {
              usersData = [...userData];
            } else {
              console.error("Expected userData to be an array, but got:", userData);
            }
          }
        }
        setUsers3(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (locationInfo2.length > 0) {
      fetchUsers();
    }
  }, [locationInfo2]);

  const getOptions1 = (user) => {
    if (!Array.isArray(users22)) {
      console.error("users22 is not an array:", users22);
      return [];
    }

    if (!user || !user.members) {
      console.error("User or user members are undefined:", user);
      return [];
    }

    return users22
        .filter(e => user.members.includes(e.id))
        .map(e => ({ value: e.id, label: `${e?.first_name} ${e?.last_name} ${e?.patronymic}` }));
  };

  useEffect(() => {
    const user =users3 && users3.find(user => groupId === user.group);
    if (user) {
      setUserWH(user);
    }
  }, [users3, groupId]);

  const handleChange1 = (selectedOption) => {
    setSelectedOption(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      approver: selectedOption ? selectedOption.value : ""
    }));
    whiteID(selectedOption);
    console.log("Selected option:", selectedOption);
  };

  console.log(!userEH?.members ,"1234567890")
  return (
      <div className={style.modal_container}>
        <div className={style.modal_window}>
          <div className={style.topIfo}>
            <p>Автор
              заявки: <span>{wew ? ` ${wew[0].kam_user_detail.first_name}  ${wew[0].kam_user_detail.last_name} ${wew[0].kam_user_detail.patronymic}` : JSON.parse(localStorage.getItem("userName"))}</span>
            </p>
            <p>Дата
              заявки: <span>{wew ? ` ${wew[0].created_at.split('T')[0]}  ${wew[0].created_at.split('T')[1].split('.')[0]}` : `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()} г.  ${NewDatan.split(' ')[4]}`}</span>
            </p>
            <p>Статус: <span className={style.draft}>{wew ? wew[0].status : "Черновик"}</span><img
                src={wew ? Reviver : DraftIcon} alt='Черновик' /></p>
          </div>

          <img className={style.closeIcon} src={closeIcon} alt="" onClick={() => {
            setApdateID('');
            setModalWindow(false);
          }} />
          {wew ? <p>Номер заявки-{wew[0].id} </p> : <p>Чтобы добавить новую заявку - заполните поля</p>}
          <form onSubmit={apdateID.category_detail ? handleChangePatch : handleSubmit}>
            <div className={style.input_blocks} style={{ margin: '20px 0' }}>
              {wew && !merchant ?
                  <label htmlFor="" className={style.merchantidStyle}>
                    Категория ML6*
                    <input
                        value={wew?.[0]?.category_detail?.ML6 || ''}
                        type="text"
                        readOnly
                    />
                  </label>

                  : !merchant ? <label htmlFor="">
                        <Select
                            className={`${style.select} ${submissionError.category && style.error}`}
                            options={categories.map(category => ({
                              value: !merchant && category.id,
                              description: !merchant && category.ML6,
                              label: !merchant && `${category.ML1} ${category.ML2} ${category.ML3} ${category.ML4} ${category.ML5} ${category.code}`,
                            }))}
                            onChange={option => {
                              setCategoryID(option.value);
                              handleChange("category", option.value);
                            }}
                            onInputChange={(value) => handleSearch(value)}
                            onMenuScrollToBottom={handleScroll}
                            placeholder={apdateID.category_detail ? apdateID.category_detail.ML6 : "Выберите категорию ML6*"}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                border: '0px solid #ccc',
                                padding: ' 8px 0',
                                cursor: 'pointer',
                                position: "relative",
                                zIndex: 3
                              }),
                              menu: (provided, state) => ({
                                ...provided,
                                backgroundColor: '#F8F8F8',
                                marginTop: '8px',
                                border: '1px solid #EBEBEB',
                                cursor: 'pointer',
                                position: "absolute",
                                zIndex: 3
                              }),
                            }}
                            formatOptionLabel={({ label, description }) => (
                                <div>
                                  <div>{description}</div>
                                  <div style={{ fontSize: '12px', color: '#666' }}>{label}</div>
                                </div>
                            )}
                            filterOption={({ label, description }, inputValue) => {
                              const lowerCaseInput = inputValue.toLowerCase();
                              return (label && label.toLowerCase().includes(lowerCaseInput)) ||
                                  (description && description.toLowerCase().includes(lowerCaseInput));
                            }}
                        />
                        {submissionError.category && (
                            <div style={{ position: "relative" }}>
                              <p className={style.error33}>{erorText}</p>
                            </div>
                        )}
                      </label> :
                      <label><input style={{ textAlign: "start", background: '#F7F7F7' }} readOnly
                                    value={""} type="text" /></label>
              }
              {wew ?
                  <div className={style.quantBlock}>
                    <label htmlFor="" className={style.merchantidStyle}>
                      ID мерчанта
                      <input value={wew[0].merchant_id} type={"text"} readOnly />

                    </label>

                  </div>
                  :
                  <div className={style.quantBlock}>
                    <label htmlFor="" className={`${style.merchantidStyle} ${style.merchantidStyle2}`}>
                      <label htmlFor="">
                        <div className={` ${merchant && submissionError.merchant_id && style.error}`}>
                          <input
                              className={`${style.select} ${merchant && submissionError.merchant_id && style.error}`}
                              placeholder={'Укажите ID мерчанта'}
                              value={formData.merchant_id}
                              onChange={e => {
                                handleChange("merchant_id", e.target.value);
                                setMerchantid(e.target.value);
                              }}
                          />
                          {merchant && submissionError.merchant_id && (
                              <div style={{ position: "relative" }}>
                                <p className={style.error33}>{erorText}</p>
                              </div>
                          )}
                        </div>
                      </label>
                    </label>
                  </div>}
              {wew ?
                  <label htmlFor="" className={style.merchantidStyle} style={{ width: '300px' }}>
                    Ценовой сегмент
                    <input style={{ width: '300px' }} value={wew[0].price_segment} type={"text"} readOnly />
                  </label>

                  : <label htmlFor="" style={{ width: '300px' }}>
                    <Select
                        className={`${style.select} ${submissionError.price_segment && style.error}`}
                        placeholder={'Выберите ценовой сегмент'}
                        value={priceSegment.value}
                        options={[
                          { value: 'Высокий', label: 'Высокий' },
                          { value: 'Средний', label: 'Средний' },
                          { value: 'Низкий', label: 'Низкий' },
                          { value: '', label: 'Не выбран' }
                        ]}
                        onChange={option => {
                          handleChange("price_segment", option.value);
                          setPriceSegment(option.value);
                        }}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            border: '0px solid #ccc',
                            backgroundColor: 'rgba(255,255,255,0)',
                            padding: ' 8px 0',
                            cursor: 'pointer',
                            width: '300px'
                          }),
                          menu: (provided, state) => ({
                            ...provided,
                            backgroundColor: '#F8F8F8',
                            marginTop: '8px',
                            border: '1px solid #EBEBEB',
                            cursor: 'pointer',
                            zIndex: 99999999,
                          }),
                        }}
                    />
                  </label>
              }
            </div>
            <div className={style.input_blocks}>
              {wew ?
                  <label htmlFor="" className={style.merchantidStyle}>
                    РЦ
                    <input value={wew[0].warehouse_detail.location} type={"text"} readOnly />
                  </label>

                  : <label htmlFor="">
                    <Select
                        className={`${style.select} ${submissionError.warehouse && style.error}`}
                        options={finalOptions
                            .filter(location => !location.is_closed)
                            .map(location => ({
                              value: location.id,
                              label: location.location,
                            }))
                        }
                        onChange={option => {
                          setWarehouseID(option.value);
                          handleChange("warehouse", option.value);
                        }}
                        placeholder={apdateID.warehouse_detail ? apdateID.warehouse_detail.location : "Выберите РЦ"}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            border: '0px solid #ccc',
                            padding: ' 5px 0',
                            position: "relative",
                            zIndex: 2,
                          }),
                          menu: (provided, state) => ({
                            ...provided,
                            backgroundColor: '#F8F8F8',
                            marginTop: '5px',
                            border: '1px solid #EBEBEB',
                            position: "absolute",
                            zIndex: 2,
                          }),
                        }}
                    />

                    {submissionError.warehouse && (
                        <div style={{ position: "relative" }}>
                          <p className={style.error33}>{erorText}</p>
                        </div>
                    )}
                  </label>
              }
              <div className={style.quantBlock}>
                {wew ?
                    <label htmlFor="" className={style.merchantidStyle}>
                      Квант первой поставки
                      <input
                          value={wew[0].new_value} type="text"
                      />
                    </label>

                    : merchant ? <label htmlFor="">
                      <input
                          placeholder={'Укажите квант первой поставки'}
                          className={`${!merchant && submissionError.new_value && style.error}`}
                          value={newValue > 9999 ? 9999 : newValue || '1000'} type="number"
                          name="new_value" onChange={(e) => {
                        const value = e.target.value > 9999 ? 9999 : e.target.value || '1000';
                        setNewValue(value);
                        handleChange("new_value", value);
                      }}
                      />
                      { submissionError.new_value && (
                          <div style={{ position: "relative" }}>
                            <p className={style.error33}>{erorText}</p>
                          </div>
                      )}
                    </label> : <label htmlFor="">
                      <input
                          placeholder={'Укажите квант первой поставки'}
                          className={` ${!merchant && submissionError.new_value && style.error}`}
                          value={newValue > 9999 ? 9999 : newValue} type="number"
                          name="new_value" onChange={(e) => {
                        const value = e.target.value > 9999 ? 9999 : e.target.value || '';
                        setNewValue(value);
                        handleChange("new_value", value);
                      }}
                      />
                      { submissionError.new_value && (
                          <div style={{ position: "relative" }}>
                            <p className={style.error33}>{erorText}</p>
                          </div>
                      )}
                    </label>
                }
                <div>
                  <div className={style.indicator}>
                    <div
                        className={status === "red" || wew && wew[0].status_lights === "red" ? `${style.indicator1} ${style.indicator1Activ} ` : style.indicator1}></div>
                    <div
                        className={status === "yellow" || wew && wew[0].status_lights === "yellow" ? `${style.indicator2} ${style.indicator2Activ}` : style.indicator2}></div>
                    <div
                        className={status === "green" || wew && wew[0].status_lights === "green" ? `${style.indicator3} ${style.indicator3Activ}` : style.indicator3}></div>
                  </div>
                  <p className={style.grayText}>*Одобрение <br /> весьма вероятно</p>
                </div>
              </div>
              {wew ?
                  <label htmlFor="" style={{ width: '300px' }}>
                    Срок жизни кванта
                    <input
                        style={{ width: '300px', textAlign: 'left' }}
                        value={wew?.[0]?.date_new_value || ''}
                        type="text"
                    />
                  </label>

                  : <label className={`${style.calendarBlock} calendar-block`} htmlFor="inputDate"
                           style={{ width: '300px' }}>
                    <div className={`${style.calendar} calendar`}>
                      {calendarWindow ? (
                          <StyledCalendar
                              minDate={new Date()}
                              maxDate={new Date(new Date().setDate(new Date().getDate() + 30))}
                              onChange={(e) => {
                                handleChange("date_new_value", `${e.getFullYear()}-${(e.getMonth() + 1).toString().padStart(2, '0')}-${e.getDate().toString().padStart(2, '0')}`);

                                setCalendarValue(e);

                              }}
                              value={calendarValue}
                          />
                      ) : null}
                    </div>
                    <div
                        className={calendarWindow
                            ? `${style.calendarBlockInput} ${style.calendarBlockInput2} ${merchant && !calendarValue && style.error}`
                            : ` ${style.calendarBlockInput} ${merchant && !calendarValue && submissionError.date_new_value && style.error}`
                        }
                    >
                      <input
                          style={{ width: '300px' }}
                          placeholder={'Срок жизни кванта'}
                          name={'inputDate'}
                          type="text"
                          className={`${merchant && !calendarValue && submissionError.date_new_value && style.error}`}
                          value={calendarValue ? `${calendarValue.getDate().toString().padStart(2, '0')}.${(calendarValue.getMonth() + 1).toString().padStart(2, '0')}.${calendarValue.getFullYear()}` : ''}
                      />
                      <img src={!calendarWindow ? calendarIcon : vector4} alt=""
                           onClick={() => setCalendarWindow(!calendarWindow)} />
                      <img style={{ marginRight: '30px', marginTop: "-5px" }} src={closeIcon} alt=""
                           onClick={() => {
                             setCalendarValue('');
                             handleChange("date_new_value", null);
                           }} />
                    </div>
                    {merchant && !calendarValue && submissionError.date_new_value && (
                        <div style={{ position: "relative" }}>
                          <p className={style.error33}>{erorText}</p>
                        </div>
                    )}
                    {calendarValue && submissionError.past_date_error && (
                        <div style={{ position: "relative" }}>
                          <p className={style.error33}>* дата не может быть в прошлом или более чем на 30 дней вперед</p>
                        </div>
                    )}
                  </label>


              }
            </div>
            <div className={style.indicatorsBlock}>
              <div className={style.indicators}>
                {wew && <div className={style.titleGetWss}>
                  <p>РЦ</p>
                  <p>МСК</p>
                  <p>Регионы</p>
                </div>}
                <div>
                  <p className={style.indicatorsBlockText}>
                    Продажи на 1SKU 2P, дн
                    <span>
                                        <p>{newValue && wssDate2.P2rest ? +wssDate2.P2rest : wew && wew[0].sales_data.current_rc?.p2.sales !== "Нет данных" && wew[0].sales_data.current_rc?.p2.sales ||
                                            <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>} </p>
                      {wew && <>
                        <p>{newValue && wssDate2.P2MoscowRest ? +wssDate2.P2MoscowRest.toFixed(1) : wew && wew[0].sales_data.moscow?.p2.sales !== "Нет данных" && wew[0].sales_data.moscow?.p2.sales ||
                            <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>} </p>
                        <p>{newValue && wssDate2.P2regionsRest ? +wssDate2.P2regionsRest.toFixed(1) : wew && wew[0].sales_data.regions?.p2.sales !== "Нет данных" && wew[0].sales_data.regions?.p2.sales ||
                            <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>} </p></>}
                                    </span>
                  </p>

                </div>
                <div className={style.grayIndicatorBlock}>
                  <p className={style.indicatorsBlockText}>
                    Прогноз оборота кванта, дн
                    <span className={style.indicatorsBlockTextWeit}>
                                        <p>{newValue && +wssDate2?.p2 ? wssDate2?.p2 :
                                            wew && wew[0].sales_data.current_rc?.p2.pokd !== "Нет данных" && wew[0].sales_data.current_rc?.p2.pokd ||
                                            <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>}</p>
                      {wew && <>  <p>{newValue && +wssDate2?.moscow_2p ? wssDate2?.moscow_2p :
                          wew && wew[0].sales_data.moscow?.p2.pokd !== "Нет данных" && wew[0].sales_data.moscow?.p2.pokd ||
                          <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>}</p>
                        <p>{newValue && +wssDate2?.regions_2p ? wssDate2?.regions_2p :
                            wew && wew[0].sales_data.regions?.p2.pokd !== "Нет данных" && wew[0].sales_data.regions?.p2.pokd ||
                            <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>}</p>
                      </>}
                                    </span>
                  </p>

                </div>
                <div>
                  <p className={style.indicatorsBlockText}>
                    Продажи на 1SKU 1P, дн
                    <span>
                                        <p>{newValue && wssDate2?.P1rest ? wssDate2?.P1rest : wew && wew[0].sales_data.current_rc?.p1.sales !== "Нет данных" && wew[0].sales_data.current_rc?.p1.sales || <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>}</p>
                      {wew && <>  <p>{newValue && wssDate2?.P1MoscowRest ? wssDate2?.P1MoscowRest : wew && wew[0].sales_data.moscow?.p1.sales !== "Нет данных" && wew[0].sales_data.moscow?.p1.sales || <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>} </p>
                        <p>{newValue && wssDate2?.P1regionsRest ? wssDate2.P1regionsRest : wew && wew[0].sales_data.regions?.p1.sales !== "Нет данных" && wew[0].sales_data.regions?.p1.sales || <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>} </p></>}
                                    </span>
                  </p>
                </div>
                <div className={style.grayIndicatorBlock}>
                  <p className={style.indicatorsBlockText}>
                    Прогноз оборота кванта, дн
                    <span className={style.indicatorsBlockTextWeit}>
                                        <p>{newValue && +wssDate2?.p1 ? wssDate2?.p1 :
                                            wew && wew[0].sales_data.current_rc?.p1.pokd !== "Нет данных" && wew[0].sales_data.current_rc?.p1.pokd ||
                                            <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>}
                                        </p>
                      {wew && <>   <p>{newValue && +wssDate2?.moscow_1p ? wssDate2?.moscow_1p :
                          wew && wew[0].sales_data.moscow?.p1.pokd !== "Нет данных" && wew[0].sales_data.moscow?.p1.pokd ||
                          <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>}
                      </p>
                        <p>{newValue && +wssDate2?.regions_1p ? wssDate2?.regions_1p :
                            wew && wew[0].sales_data.regions?.p1.pokd !== "Нет данных" && wew[0].sales_data.regions?.p1.pokd ||
                            <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>}
                        </p></>}
                                    </span>
                  </p>
                </div>
                <div>
                  <p className={style.indicatorsBlockText}>
                    Остаток на РЦ
                    <span>
                                        <p>{newValue && (wssDate2.rest + wssDate2.rest2) ||
                                            wew && wew[0].sales_data.current_rc.p1.rest !== "Нет данных" && wew[0].sales_data.current_rc.p1.rest
                                            +
                                            wew[0].sales_data.current_rc.p2.rest !== "Нет данных" && wew[0].sales_data.current_rc.p2.rest

                                            || <span style={{ color: "gray", fontSize: '8px' }}>Н/Д</span>}</p>
                      {wew && <> <p>-</p>
                        <p>-</p></>}
                                    </span>
                  </p>
                </div>
              </div>
              <div className={style.indicators2}>
                <p>Текущий квант <span
                    className={style.indicatorsBlockTextWeit}>{wew && wew[0].current_quantity || wssDate.Текущий_Квант || "Н/Д"}</span>
                </p>
                <p className={style.gray}>Максимальная
                  заявка <span>{wew && wew[0].max_requested_quantity || wssDate.Мах_запрошено || "Н/Д"}</span>
                </p>
                <p>Изменение % <span>{wew && wew[0].delta || newValue && wssDate2.Дельта || "Н/Д"}</span></p>
                <p className={style.gray}>Изменение,
                  шт <span>{wew && wew[0].change || newValue && wssDate2.Изменение || "Н/Д"}</span></p>
              </div>
              <div style={{ paddingTop: '25px' }}>

                <div className={`${style.select} ${!selectedOption && submissionError.approver && style.error}`}>
                  {userEH?.members?.length ?
                      <Select
                          placeholder={'Супервайзер белого списка'}
                          options={getOptions1(userEH)}
                          value={selectedOption}
                          onChange={handleChange1}

                      /> : <div style={{position:"relative"}}>
                        <div    style={{position:"absolute",zIndex:1,width:'100%',height:'200%',top:0,left:0}}></div>
                        <Select
                            placeholder={'Супервайзер белого списка'}
                            options={getOptions1(userEH)}
                            value={selectedOption}
                            onChange={handleChange1}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                border: '0px solid #ccc',
                                padding: ' 5px 0',
                                position: "relative",

                                backgroundColor:"#F7F7F7",
                              }),
                              menu: (provided, state) => ({
                                ...provided,
                                backgroundColor: "#F7F7F7",
                                marginTop: '5px',
                                border: '1px solid #EBEBEB',
                                position: "absolute",

                              }),
                            }}                            />
                      </div>}
                  {!selectedOption && submissionError.approver && (
                      <div style={{ position: "relative" }}>
                        <p className={style.error33}>{erorText}</p>
                      </div>
                  )}
                </div>



                {wew ? (
                    <div style={{ marginBottom: '5px' }}>
                      <div className={style.input_blocks} style={{ width: '300px', justifyContent: "flex-start" }}>
                        <label className={style.checkboxContainer}>
                          <input
                              className={style.hiddenCheckbox}
                              type="checkbox"
                              id="customCheckbox3"
                              name="customCheckbox3"
                              checked={wew[0].merchant_flag}
                              readOnly
                          />
                          <label
                              className={`${style.customCheckbox} ${wew[0].merchant_flag ? style.checked : ''}`}
                              htmlFor="customCheckbox3"
                              style={!wew[0].merchant_flag ? { backgroundColor: "#F7F7F7" } : {}}
                          ></label>
                          <span className={style.checkboxLabel} style={!wew[0].merchant_flag ? { color: "#F7F7F7" } : {}}>{userEH?.members?.length ? "Белый список" : "Черный список"}</span>
                        </label>
                      </div>
                    </div>
                ) : (
                    <div style={{ marginTop: '5px' }}>
                      <div className={style.input_blocks} style={userEH?.members?.length ? { width: '300px', justifyContent: "flex-start" } : { width: '300px', justifyContent: "flex-start" }}>
                        <label className={`${style.checkboxContainer} ${!userEH?.members ? style.disabledCheckbox : ''}`}>
                          <input
                              style={!userEH?.members?.length ? { background: "#F7F7F7" } : null}
                              className={style.hiddenCheckbox}
                              type="checkbox"
                              id="customCheckbox3"
                              name="customCheckbox3"
                              checked={userEH?.members?.length ? merchant : false}
                              onChange={() => {
                                if (userEH?.members) {
                                  setMerchant(!merchant);
                                  handleChange("merchant_flag", !merchant);
                                }
                              }}
                              disabled={!userEH?.members?.length}
                          />
                          <label
                              className={`${style.customCheckbox} ${userEH?.members && merchant ? style.checked : ''} ${!userEH?.members ? style.disabledCustomCheckbox : ''}`}
                              style={!userEH?.members?.length ? { backgroundColor: "#F7F7F7" } : null}
                              htmlFor="customCheckbox3"
                          ></label>
                          <span className={`${style.checkboxLabel} ${!userEH?.members ? style.disabledCheckboxLabel : ''}`} style={!userEH?.members?.length ? { color: "#9b9fa2" } : null}>Белый список</span>
                        </label>
                      </div>
                    </div>
                )}

                <div className={`${style.indicators3}`}>
                  <div className={style.input_blocks} style={{width: '300px', display: 'block'  ,paddingTop:"30px"}}>
                    <label className={style.checkboxContainer} style={{marginBottom:"10px"}}>
                      <input
                          className={style.hiddenCheckbox}
                          type="checkbox"
                          id="customCheckbox2"
                          name="customCheckbox2"
                          checked={!wew && isChecked2 || wew && wew[0].promo}
                          onChange={() => {
                            if (!wew) {
                              setIsChecked2(!isChecked2);
                              handleChange("promo", !isChecked2);
                            }
                          }}
                      />
                      <label
                          className={`${style.customCheckbox} ${!wew && isChecked2 || wew && wew[0].promo ? style.checked : ''}`}
                          htmlFor="customCheckbox2"
                      ></label>
                      <span className={style.checkboxLabel}>Промо поддержка</span>
                    </label>
                    <label className={style.checkboxContainer}>
                      <input
                          className={style.hiddenCheckbox}
                          type="checkbox"
                          id="customCheckbox"
                          name="customCheckbox"
                          checked={!wew && isChecked || wew && wew[0].seasonality}
                          onChange={() => {
                            if (!wew) {
                              setIsChecked(!isChecked);
                              handleChange("seasonality", !isChecked);
                            }

                          }}
                      />
                      <label
                          className={`${style.customCheckbox} ${!wew && isChecked || wew && wew[0].seasonality ? style.checked : ''}`}
                          htmlFor="customCheckbox"
                      ></label>
                      <span className={style.checkboxLabel}>Сезонный ассортимент</span>
                    </label>

                  </div>
                </div>
              </div>

            </div>
            {wew ? <label htmlFor="">
                  <p style={{ fontSize: "16px" }}>Причина изменения кванта</p>
                  <textarea
                      placeholder=""
                      className={style.textarea}
                      value={wew[0].reason}
                      name="reason"
                  />
                </label>
                : <label htmlFor="" className={`${style.label} ${style.textareaContainer}`}>
                  <div className={style.customPlaceholder}>
                    Укажите причину изменения кванта.
                    <br /> <br />
                    <span className={style.importantText}>Важно!</span> для категорий по которым ранее квант
                    был
                    не выставлен или был равен 0 необходимо максимально подробно дать инфо:
                    <br /> . кол-во скю к поставке в данной категории,
                    <br /> . к какой габаритной кат относится :МГТ, СГТ, КГТ,
                    <br /> . ценовой сегмент (Ср/ВхЦ),
                    <br /> . если корректировка по конкретному мерчату, то указать представленность у
                    конкурента
                    прогноз продаж и т.п (максимально дать вводную информацию).
                  </div>
                  <textarea
                      placeholder=""
                      className={`${style.textarea} ${submissionError.reason && style.error || submissionError.reason_only_spaces && style.error}`}
                      value={formData.reason}
                      name="reason"
                      onChange={(e) => handleChange("reason", e.target.value)}
                      onFocus={() => document.querySelector(`.${style.customPlaceholder}`).style.display = 'none'}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          document.querySelector(`.${style.customPlaceholder}`).style.display = 'block';
                        }
                      }}

                  />
                  {submissionError.reason && (
                      <div style={{ position: "relative" }}>
                        <p className={style.error33}>{erorText}</p>
                      </div>
                  )}
                  {submissionError.reason_only_spaces && (
                      <div style={{ position: "relative" }}>
                        <p className={style.error33}>{erorText}</p>
                      </div>
                  )}
                </label>}
            {!wew && <div className={style.butns}>
              <button className={'add_btn'} type="submit">Сохранить</button>
              <button className={'sand_btn'} type={'button'} onClick={() => {
                setApdateID('');
                setModalWindow(false);
              }}>Отменить
              </button>
              {apdateID.category_detail ?
                  <button type={"button"} className={"sand_btn"} onClick={handleDelete}>
                    Удалить
                  </button> : null}
            </div>}

            {
                wew && <div style={{ display: "flex", justifyContent: 'center' }}>
                  <button className={'sand_btn green_btn '} onClick={() => {
                    setNewID(wew[0].id);
                    setModalWindow3(true);
                  }}>Согласовать
                  </button>
                  <button className={'sand_btn red_btn '} onClick={() => {
                    setNewID(wew[0].id);
                    setModalWindow7(true);
                  }}>Отклонить
                  </button>
                  <button className={'sand_btn orange_btn '} onClick={() => {
                    setNewID(wew[0].id);
                    setModalWindow4(true);
                  }}>Корректировка
                  </button>
                </div>
            }
            {loader ? <img className={style.loader} src={loaderIMG} alt="" /> : null}
          </form>

        </div>
      </div>
  );
}

export default NewApplication;
