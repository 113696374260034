import {BaseUrl} from "./url";

export const dateFetch = function ( url,requestOptions,setLocationInfo){ fetch(`${BaseUrl}${url}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
        setLocationInfo(JSON.parse(result));
    })
    .catch((error) => console.error(error));}
export const dateFetch2 = function (url, requestOptions) {
    return fetch(BaseUrl + url, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            throw error;
        });
};
