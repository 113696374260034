import React from "react";
import styles from "./AplicationModal.module.css";
import img  from "../../../imgs/icons/одобрено.svg";
export default function AplicationModal({ text }) {
    const [open, setOpen] = React.useState(true);
    setTimeout(()=>setOpen(false) , 5000)
    return open? (
        <div    className={styles.modalBlock}>
            <img  src={img} alt=""/>
         <p>{text}</p>
        </div>
    ):null
}