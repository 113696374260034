import React, { useState } from "react";
import style from "./Login.module.css";
import { BaseUrl } from "../../url";
import { useNavigate } from "react-router-dom";
import loader from '../../imgs/icons/loader.webp'

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    // const refreshToken2 = localStorage.getItem("refreshToken");
    //
    // const refreshToken = () => {
    //     const refreshToken = localStorage.getItem("refreshToken");
    //
    //     if (!refreshToken) {
    //         navigate('/login');
    //         return;
    //     }
    //
    //     const myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //
    //     const raw = JSON.stringify({
    //         refresh: refreshToken
    //     });
    //
    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: raw
    //     };
    //
    //     fetch(BaseUrl + "/api/token/refresh/", requestOptions)
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error("Ошибка обновления токена");
    //             }
    //             return response.json();
    //         })
    //         .then((result) => {
    //             const token = result.access;
    //             localStorage.setItem("token", JSON.stringify(token));
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //             navigate('/login');
    //         });
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        // refreshToken();

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            username: username,
            password: password
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw
        };

        fetch(`${BaseUrl}/api/token/`, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Неверные учетные данные");
                }
                return response.json();
            })
            .then((result) => {
                const refreshToken  =  result.refresh
                localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
                const role = result.role;
                localStorage.setItem("role", JSON.stringify(role));
                const token = result.access;
                localStorage.setItem("token", JSON.stringify(token));
                const userName = result.last_name + " " + result.first_name;
                localStorage.setItem("userName", JSON.stringify(userName));
                const userID = result.id;
                localStorage.setItem("userId", JSON.stringify(userID));
                navigate('/workPage');
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className={style.form}>
                <form className={style.formBlock} onSubmit={handleSubmit}>
                    <h2>Авторизация</h2>
                    {error && <p className={style.error}>{error}</p>}
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                    {loading? <img className={'loader'} src={loader} alt=""/>:  <button type="submit" className={"button " + style.formBtn} >
                        Войти
                    </button> }
                </form>
            </div>
        </>
    );
}
