import React, { useEffect, useState } from "react";
import style from "../DistributionCenter/DistributionCenter.module.css";
import { dateFetch, dateFetch2 } from "../../../../fetch";
import deleteIcon from '../../../../imgs/icons/24.png'
import styleSearch from "../../Reference.module.css";
import ResponseOk from "../../../../components/modalWindos/responseOK/ResponseOk";
import loadingIMG from "../../../../imgs/icons/loader.webp";
import img1 from "../../../../imgs/icons/одобрено.svg"
import img2 from "../../../../imgs/icons/Vector.svg"
export default function Employees() {
    const [locationInfo, setLocationInfo] = useState([]);
    const [addUser, setAddUser] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [responseOn, setResponseOn] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading,setLoading] = useState(true);
    const [changeUser, setChangeUser] = useState("");
    const [validateInputsErrors, setValidateInputsErrors] = useState("");
    const [userName, setUserName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userRole, setUserRole] = useState("");
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + JSON.parse(localStorage.getItem("token")));
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    const url = "/api/users/";

    useEffect(() => {
        dateFetch(url, requestOptions, setLocationInfo);
        setLoading(false)
    }, []);

    myHeaders.append("Content-Type", "application/json");
    const requestOptionsPost = {
        method: "POST",
        redirect: "follow",
        headers: myHeaders,
        'Content-Type': 'application/json'
    };

    function handleSubmit(e) {
        e.preventDefault();
        const username = e.target.elements.username.value.trim();
        const fullName = e.target.elements.fullName.value.trim();
        const email = e.target.elements.email.value.trim();
        const role = e.target.elements.role.value;

        if (!validateInputs(username, fullName, email)) {
            return;
        }

        const newUser = {
            "username": username,
            "first_name": fullName.split(' ')[0],
            "last_name": fullName.split(' ')[1] || "",
            "patronymic": fullName.split(' ')[2] || "",
            "email": email,
            "role": role
        };

        const updatedRequestOptionsPost = {
            ...requestOptionsPost,
            body: JSON.stringify(newUser)
        };

        dateFetch2(url, updatedRequestOptionsPost)
            .then(() => {
                setResponseOn(true);
                return dateFetch(url, requestOptions, setLocationInfo);
            })
            .catch(error => {
                console.error('Error:', error);
                setValidateInputsErrors("Адрес электронной почты недействителен")
            });
    }

    useEffect(()=>{
        setUserName( changeUser.username)
        setUserLastName(changeUser.last_name)
        setUserRole( changeUser.role)
    },[changeUser]);

    const validateInputs = (username, fullName, email) => {
        const errors = {};

        if (!/^[a-zA-Z0-9]+$/.test(username)) {
            errors.username = "Имя пользователя должно содержать только буквенно-цифровые символы";
        }

        if (username.includes(" ")) {
            errors.username = "Имя пользователя не может содержать пробелы";
        }

        if(!email){
            errors.email = "Поле электронной почты не может быть пустым";
        }else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Адрес электронной почты недействителен";
        }

        setErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handlePatchUser = (userId) => {
        const updatedUserData = {
            username: userName,
            last_name: userLastName.split(' ')[1],
            first_name: userLastName.split(' ')[0],
            patronymic: userLastName.split(' ')[2] || "",
            role: userRole // Use the userRole state here
        };


        const patchRequestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: JSON.stringify(updatedUserData)
        };

        const patchUrl = `/api/users/${userId}/`;

        dateFetch2(patchUrl, patchRequestOptions)
            .then(() => {
                setResponseOn(true);
                handleCancelEdit()
                setValidateInputsErrors('')
                return dateFetch(url, requestOptions, setLocationInfo);

            })
            .catch(error => {
                console.error('Error:', error);
                setValidateInputsErrors("Ошибка при обновлении пользователя");
            });


    };

    const handleCancelEdit = () => {
        setUserName(changeUser.username);
        setUserLastName(changeUser.last_name);
        setChangeUser("");
    };

    const filteredLocationInfo =locationInfo.length ? locationInfo.filter(item => {
        const fields = ["username", "first_name", "last_name", "email", "role"];
        return fields.some(field => item[field].toLowerCase().includes(searchQuery.toLowerCase()));
    }):null;
    return (
        <div className={style.container}>
            {responseOn ? <ResponseOk setResponseOn={setResponseOn} /> : null}
            <h2>Сотрудники</h2>
            <input
                className={styleSearch.search}
                type="search"
                placeholder="Поиск"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className={`${style.table}`}>
                <div className={`${style.titles}   ${style.titles2}`}>
                    <p>Пользователь</p>
                    <p>ФИО</p>
                    <p>Почта</p>
                    <p>Описание</p>
                </div>
                <table className={style.container}>
                    {loading ? (
                        <div className={'ladingBlock'}>
                            <img src={loadingIMG} alt=""/>
                        </div>
                    ) :filteredLocationInfo? filteredLocationInfo.map(e => ( <>
                        <tr className={changeUser.id === e.id ? style.pinkLine:""} key={e.id}>
                            {changeUser.id !== e.id ? <>
                                    <td><input className={style.input} type="text" value={e.username} readOnly/></td>
                                    <td><input className={style.input} type="text"
                                               value={`${e.first_name} ${e.last_name} ${e.patronymic}`}/></td>
                                    <td><input className={style.input} type="text" value={e.email}/></td>
                                    <td><input className={style.input} type="text" value={e.role}/></td>
                                </> :
                                <>
                                    <td><input className={`${style.input} ${style.input3}`} type="text" value={userName}
                                               onChange={(e)=>setUserName(e.target.value)}/></td>
                                    <td><input className={`${style.input} ${style.input3}`} type="text"
                                               value={userLastName}
                                               onChange={(e) => setUserLastName(e.target.value)}/></td>
                                    <td><input className={style.input} type="text" value={e.email}/></td>
                                    <td>
                                        <select
                                            value={ userRole?userRole:e.role }
                                        className={`${style.input} ${style.input3}`}
                                        name="roleChange"
                                        id="roleChange"
                                        onChange={(e) => setUserRole(e.target.value)}
                                        >
                                            <option className={style.input} value={"KAM"}>KAM</option>
                                            <option className={style.input } value={"admin"}>admin</option>
                                            <option className={style.input} value={"supervisor"}>supervisor</option>
                                        </select>
                                    </td>

                                </>
                            }
                            <td className={`${style.ChangeButton} `}>
                                {changeUser.id !== e.id ?
                                    <button className={'sand_btn'}
                                        onClick={() => setChangeUser({
                                        id: e.id,
                                        username: e.username,
                                        last_name:e.first_name +" "+ e.last_name + " " + e.patronymic,
                                        role: e.role,
                                    })}>Изменить</button> :
                                    <div className={style.changeImgs}>
                                        <img src={img1} alt="1" onClick={() => handlePatchUser(e.id)}/>
                                        <img src={img2} alt="2" onClick={handleCancelEdit}/>
                                    </div>
                                }
                            </td>
                        </tr>
                       {validateInputsErrors && changeUser.id === e.id ? <p className={style.error}>{validateInputsErrors}</p> : null}
               </>
                    )):null}
                    {addUser.map((user, index) => (
                        <tr className={style.added} key={`new_${index}`}>
                            <td><input className={style.input} type="text" value={user.username} readOnly/></td>
                            <td><input className={style.input} type="text"
                                       value={`${user.first_name} ${user.last_name} ${user.patronymic}`} readOnly/></td>
                            <td><input className={style.input} type="text" value={user.email} readOnly /></td>
                            <td><input className={style.input} type="text" value={user.role} readOnly /></td>
                            <img
                                onClick={() => {
                                    const updatedAddRC = addUser.filter((item, i) => i !== index);
                                    setAddUser(updatedAddRC);
                                }}
                                className={style.deleteIcon}
                                src={deleteIcon}
                                alt=""
                            />
                        </tr>
                    ))}
                </table>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={`${style.inputsBlock}  ${style.employe} `}>
                    <input className={errors.username ? `${style.input} ${style.errotInput}` : style.input}
                           placeholder={'username'} name="username" type="text"/>

                    <input className={style.input} placeholder={'ФИО'} name="fullName" type="text"/>
                    <input className={errors.username ? `${style.input} ${style.errotInput}` : style.input}
                           placeholder={'Почта'} name="email" type="text"/>

                    <select className={style.input} name="role" id="role">
                        <option className={style.input}>KAM</option>
                        <option className={style.input}>admin</option>
                        <option className={style.input}>supervisor</option>
                    </select>

                    <div className={`${style.btns} ${style.btnsUserAdd}`}>
                        <button className={'sand_btn'} type={"submit"}>Добавить</button>
                    </div>
                </div>


                {errors.username && <p className={style.error}>{errors.username}</p>}
                {errors.email && <p className={style.error}>{errors.email}</p>}

            </form>
        </div>
    );
}
