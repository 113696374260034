import React, {useEffect, useState} from "react";
import style from "../GroupsEmployee/GroupsEmployee.module.css"
import {dateFetch2 , dateFetch} from "../../../../fetch";
import arrow from '../../../../imgs/icons/system-uicons_arrow.png'
import closeIcon from "../../../../imgs/icons/closeIcon.png";
import Select from "react-select";
import ResponseOk from "../../../../components/modalWindos/responseOK/ResponseOk";
export default function GroupRoles(){
    const [locationInfo, setLocationInfo] = useState([]);

    const  [users,setUsers]  = useState([]);
    const [blockOpen,setBlockOpen] = useState(0)
    const [modalOpen,setModalOpen] = useState(false)
    const [modalOpen2,setModalOpen2] = useState(false)
    const [newUserCotegories,setNewUserCotegories] = useState([])
    const [getGrups , setGetGrups] = useState([])
    const [newUser,setNewUswr] = useState()
    const [newselectedOption,setNewselectedOption] =useState('')
    const [newUser2,setNewsUser]=useState('')
    const [responseOn,setResponseOn] = useState(false)
    const [tileClassName, setTileClassName] = useState(false);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + JSON.parse(localStorage.getItem("token")));

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    myHeaders.append("Content-Type", "application/json");

    useEffect(() => {
        dateFetch('/rolegroups/', requestOptions, setLocationInfo);
        dateFetch("/groups/" , requestOptions,setGetGrups)
        dateFetch("/api/users/", requestOptions, setUsers);
    }, []);

    function deleteMembers (group,id){
        const recuestOptionsPost={
            method: "PATCH",
            headers: myHeaders,
            body: JSON.stringify({
                "id": id,
                "role":'',

            })}
        dateFetch(`/rolegroups/${id}/`, recuestOptionsPost
        );
    }
    async function addUserCategories(group, id) {
        const date = newUser.value;

        const requestOptions1 = {
            method: "PATCH",
            headers: myHeaders,
            body: JSON.stringify({
                "role": date,
                "group": group,
            })
        };

        try {
            await dateFetch2(`/rolegroups/${id}/`, requestOptions1);
            setResponseOn(true);
            setNewUswr('');

            setTimeout(async () => {
                try {
                    await dateFetch('/rolegroups/', requestOptions, setLocationInfo);
                } catch (error) {
                    console.error(error);
                }
            }, 200);
        } catch (error) {
            console.error(error);
        }
    }


    return(
        <div className={style.container}>

            <h2> <pre> </pre></h2>
            {responseOn? <ResponseOk setResponseOn={setResponseOn}/>:null}
            <button className={'add_btn'} onClick={()=>setModalOpen2(true)}>создать новую</button>
            {modalOpen2 ? <form className={style.modalForm} onSubmit={''}>

                <div className={style.inpBlock}>
                    <img className={"closeIcon"} src={closeIcon} onClick={() => setModalOpen(false)}
                         alt=""/>
                    <p>Чтобы добавить группу заполните поля</p>
                    <div className={style.inputsBlock}>
                        <Select
                            placeholder={"Выберите группу"}

                            options={getGrups
                                .filter(group => !locationInfo.some(info => info.group === group.id))
                                .map(e => ({ value: e.id, label: e.name }))
                            }
                            onChange={e=>setNewselectedOption(e)}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    margin: '10px 0',
                                    background:' rgba(220, 221, 224, 0.3)',
                                    borderRadius:'8px'
                                })
                            }}
                        />
                        <Select
                            placeholder={"Выберите роль"}
                            options={[
                                { value: "KAM", label: "KAM" },
                                { value: "supervisor", label: "supervisor" }
                            ]}
                            onChange={e=>setNewsUser(e)}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    margin: '10px 0',
                                    background:' rgba(220, 221, 224, 0.3)',
                                    borderRadius:'8px'
                                })
                            }}
                        />
                    </div>
                    <div className={style.btns}>
                        <button className={'add_btn'} type={'button'} onClick={async () => {
                            try {
                                await dateFetch('/rolegroups/', requestOptions, setLocationInfo); // Обновление данных
                                await dateFetch(`/rolegroups/`, {
                                    method: "POST",
                                    headers: myHeaders,
                                    body: JSON.stringify({

                                        group: newselectedOption.value,

                                        "role": newUser2.value,

                                    })
                                });


                                await dateFetch('/rolegroups/', requestOptions, setLocationInfo);
                                await dateFetch("/groups/", requestOptions, setGetGrups);
                                setModalOpen2(false);
                                setResponseOn(true)
                            } catch (error) {
                                console.error(error);
                            }
                        }}>Добавить связку
                        </button>
                        <button className={'sand_btn'} type="button" onClick={() => setModalOpen2(false)}>Отменить
                        </button>
                    </div>

                </div>

            </form> : null}
            {locationInfo.length > 0 && locationInfo.map((e, index) => {
                return (
                    <div onClick={() => setBlockOpen(index)}>

                        <div
                            className={locationInfo[blockOpen]?.id === e.id && e.id === tileClassName ? style.openTitle : style.tile}
                            onClick={() => setTileClassName(tileClassName === e.id ? '' : e.id)}>

                            <p>{getGrups ? getGrups.map(y => {
                                return +e.group === +y.id ? y.name : null
                            }) : null}</p>
                            <img
                                className={locationInfo[blockOpen]?.id === e.id && e.id === tileClassName ? style.arrow : style.arrowDown}
                                src={arrow} alt=""/>
                        </div>

                        {locationInfo[blockOpen]?.id === e.id&& e.id===tileClassName?   (
                            <div>
                                <table className={style.table}>
                                    {!newUser?<tr key={e}>
                                        <td><input className={`${style.input} `} type="text" value={e.role}/>
                                        </td>
                                    </tr>:null}

                                    {newUser?
                                        (<tr key={e}>
                                            <td ><input className={`${style.input}  ${style.addedUser}`} type="text" value={newUser.label}/>
                                            </td>
                                        </tr>)
                                        : null}
                                </table>
                                {modalOpen ? <form className={style.modalForm} onSubmit={''}>

                                    <div className={style.inpBlock}>
                                        <img className={"closeIcon"} src={closeIcon} onClick={() => setModalOpen(false)}
                                             alt=""/>
                                        <p>Чтобы изменении роли заполните поля</p>
                                        <div className={style.inputsBlock}>
                                            <Select
                                                placeholder={"Выберите роль"}
                                                options={e.role === "KAM" ?[

                                                    { value: "supervisor", label: "supervisor" }
                                                ]:[{ value: "KAM", label: "KAM" }]}
                                                onChange={(selectedOption) => setNewUswr(selectedOption)}
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        margin: '10px 0',
                                                        background:' rgba(220, 221, 224, 0.3)',
                                                        borderRadius:'8px'
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={style.btns}>
                                            {/*<button className={'add_btn'} type={'button'} onClick={()=> {*/}
                                            {/*    setNewUserCotegories([...newUserCotegories, {*/}
                                            {/*        username: e.username,*/}
                                            {/*        id: e.id,*/}
                                            {/*    }])*/}

                                            {/*    setModalOpen(false)*/}
                                            {/*}*/}
                                            {/*}>Создать связку</button>*/}
                                            <button  className={ newUser?'add_btn':'sand_btn'} type="button" onClick={()=>setModalOpen(false)}>{newUser ?"Прололжить"  : 'Отменить'}
                                            </button>
                                        </div>

                                    </div>

                                </form> : null}
                                <div className={"flex_buttons"}>
                                    <button className={'add_btn'} type={'button'}  onClick={()=>setModalOpen(true)}>Изменить</button>{

                                }
                                    <button className={newUser?'add_btn':'sand_btn'} type="submit" onClick={()=>newUser?addUserCategories(e.group , e.id):''}>Сохранить</button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                )
            })}
        </div>
    )
}