const BaseUrl = process.env.REACT_APP_BUILD === 'development'
    ? 'https://market-admin-dev.bots.winsolutions.ru'
    : 'https://market-admin.drawbot.winsolutions.ru';
// const BaseUrl ='https://market-admin-dev.bots.winsolutions.ru'

const wssUrl = process.env.REACT_APP_BUILD === 'development'
    ? 'wss://market-admin-dev.bots.winsolutions.ru/ws/quant/'
    : 'wss://market-admin.drawbot.winsolutions.ru/ws/quant/';

const wssUrl2 = process.env.REACT_APP_BUILD === 'development'
    ? 'wss://market-admin-dev.bots.winsolutions.ru/ws/delta/'
    : 'wss://market-admin.drawbot.winsolutions.ru/ws/delta/';

export { BaseUrl, wssUrl, wssUrl2 };