import React, {useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/login/Login";
import WorkPage from "../pages/worckPage/WorkPage";
import Applications from "../pages/Applications/Applications";
import History from "../pages/History/History";
import Reference from "../pages/reference/Reference";
import Ligaments from "../pages/Ligaments/Ligaments";
import Categories from "../pages/reference/components/Cotegories/Cotegories";

const routes = [
    { path: '/login', component: Login },
    { path: '/', component: Login },
    { path: '/workPage', component: WorkPage },
    { path: '/applications', component: Applications },
    { path: '/history', component: History },
    { path: '/Referance', element: <Reference /> }, // Рендер компонента Reference напрямую
    { path: '/Applications/', component: Applications },
    { path: '/History/', component: History },
    { path: '/Ligaments/', component: Ligaments },
    { path: '/Referance/:category', element: <Categories /> } // Использование параметра пути для определения рендеринга Categories
];

function RouterSettings() {

    return (
        <Routes>

            {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element || <route.component />} />
            ))}
        </Routes>
    );
}

export default RouterSettings;