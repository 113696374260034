import React from "react";
import style from "../newApplication/Index.module.css";
import img from '../../../imgs/icons/одобрено.svg'

export default function ResponseOk({setResponseOn}){

    setTimeout(()=>{
        setResponseOn(false)
    },5000)

    return (  <div className={style.modal_container1} onClick={()=>setResponseOn(false)}>
            <div className={style.modal_window1}>
                <img className={style.response1} src={img} alt=""/>
                <p>Успешно</p>
            </div>
        </div>
    )
}