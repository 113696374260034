import React, { useEffect, useState } from "react";
import style from './DistributionCenter.module.css';
import styleSearch from '../../Reference.module.css'
import { dateFetch, dateFetch2 } from "../../../../fetch";
import deleteIcon from '../../../../imgs/icons/24.png'
import closeIcon from "../../../../imgs/icons/closeIcon.png"
import ResponseOk from "../../../../components/modalWindos/responseOK/ResponseOk";
import {useNavigate} from "react-router-dom";
import loadingIMG from "../../../../imgs/icons/loader.webp";

export default function DistributionCenter() {
    const [locationInfo, setLocationInfo] = useState([]);
    const [location, setLocation] = useState('');
    const [code, setCode] = useState('');
    const [addRC, setAddRC] = useState([]);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [modalOpen,setModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [responseOn,setResponseOn] = useState(false)
    const [modal, setModal] = useState(false);
    const [loading,setLoading] = useState(true);
    const [editingIndex, setEditingIndex] = useState(null); // Состояние для отслеживания редактирования

    const [codeError, setCodeError] = useState("");
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    const navigate = useNavigate();



    if (!token) {
        navigate('/Login');
    } else {
        try {
            const parsedToken = JSON.parse(token);
            myHeaders.append("Authorization", "Bearer " + parsedToken);
        } catch (error) {
            console.error("Ошибка парсинга токена:", error);
            navigate('/Login');
        }
    }
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };

    myHeaders.append("Content-Type", "application/json");
    const requestOptionsPost = {
        method: "POST",
        body: JSON.stringify(addRC || { "location": location, "code": code }),
        redirect: "follow",
        headers: myHeaders,
        'Content-Type': 'application/json'
    };

    const url = "/warehouses/";

    useEffect(() => {
        dateFetch(url, requestOptions, setLocationInfo);
        setLoading(false)
    }, []);

    function handleSubmit(id) {
        return (e) => {
            e.preventDefault();
            if (editingIndex !== null) {
                // Если редактирование активно, отправляем PATCH-запрос
                dateFetch2(url + `${locationInfo[editingIndex].id}/`, {
                    method: "PATCH",
                    body: JSON.stringify({ "location": location, "code": code }),
                    redirect: "follow",
                    headers: myHeaders,
                    'Content-Type': 'application/json'
                })
                    .then(() => {
                        return dateFetch(url, requestOptions, setLocationInfo);
                    })
                    .then(data => {
                        setResponseOn(true)
                        setLocationInfo(data);
                        setModal(true)
                        setCodeError("");
                        setEditingIndex(null); // Сбрасываем индекс редактирования
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setCodeError("РЦ с таким кодом уже существует")
                    });
            } else {
                // Если редактирование не активно, отправляем POST-запрос
                dateFetch2(url, requestOptionsPost)
                    .then(() => {
                        return dateFetch(url, requestOptions, setLocationInfo);
                    })
                    .then(data => {
                        setResponseOn(true)
                        setLocationInfo(data);
                        setAddRC([])
                        setModal(true)
                        setCodeError("")
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setCodeError("РЦ с таким кодом уже существует")
                    });
            }
        }
    }

    function handleClose(e, id, status) {
        e.preventDefault();
        dateFetch2(url + `${id}/`, {
            method: "PATCH",
            body: JSON.stringify({ "is_closed": !status }),
            redirect: "follow",
            headers: myHeaders,
            'Content-Type': 'application/json'
        })
            .then(data => {
                dateFetch(url, requestOptions, setLocationInfo);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    function handleAddRC() {
        const existingItem = addRC.find(item => item.location === location && item.code === code);
        if (existingItem) {
            setResponseOn(true)
            setShowErrorMessage(true);
        } else {
            setAddRC([...addRC, { "location": location, "code": code }]);
            setShowErrorMessage(false);
        }
        setLocation('')
        setCode('')
    }

    const filteredLocationInfo =locationInfo ? locationInfo.filter(
        item => item.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.code.toLowerCase().includes(searchQuery.toLowerCase())
    ):<></>;

    return (
        <div className={style.container}>
            {responseOn? <ResponseOk setResponseOn={setResponseOn}/>:null}
            <h2>РЦ</h2>
            <input
                className={styleSearch.search}
                type="search"
                placeholder="Поиск"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className={`${style.titles} ${style.titles3}`}>
                <p>Название</p>
                <p className={style.inputRight}>Код</p>
                <p className={style.inputRight2}>Cтатус</p>
                <p className={style.inputRight2}>Изменение</p>
            </div>
            <div className={style.table}>
            <table className={style.container}>
                    {loading ? (
                        <div className={'ladingBlock'}>
                            <img src={loadingIMG} alt=""/>
                        </div>

                    ) : filteredLocationInfo.length ? filteredLocationInfo.map((e, index) => (
                            <tr key={e.id}>
                                <td className={editingIndex === index ?`${style.inputRight1} ` :''}>
                                    {editingIndex === index ? (
                                        <input
                                            className={style.input}
                                            type="text"
                                            value={location}
                                            onChange={(event) => setLocation(event.target.value)}
                                        />
                                    ) : (
                                        <input
                                            className={style.input}
                                            type="text"
                                            value={e.location}
                                            readOnly
                                        />
                                    )}
                                </td>
                                <td className={editingIndex === index ?`${style.inputRight} ${style.inputRight1} ` :style.inputRight}>
                                    {editingIndex === index ? (
                                        <input
                                            className={style.input}
                                            type="text"
                                            value={code}
                                            onChange={(event) => setCode(event.target.value)}
                                        />
                                    ) : (
                                        <input
                                            className={style.input}
                                            type="text"
                                            value={e.code}
                                            readOnly
                                        />
                                    )}
                                </td>
                                <td className={style.input2}>
                                    <button className={!e.is_closed ? style.btnChack : style.btnChackCloce} onClick={(r)=>handleClose(r,e.id,e.is_closed)}>{!e.is_closed?'Открыт':'Закрыт'}</button>
                                </td>
                                <td className={style.input2}>
                                    {editingIndex === index ? (
                                        <button onClick={handleSubmit(e.id)} className={'sand_btn save_button'}>Сохранить</button>
                                    ) : (
                                        <button onClick={() => {
                                            setEditingIndex(index);
                                            setLocation(e.location);
                                            setCode(e.code);
                                        }} className={'sand_btn'}>Изменить</button>
                                    )}
                                </td>
                            </tr>
                        )) : null}


                    {addRC.map((item, index) => (
                        <tr className={style.added} key={`new_${index}`}>
                            <td>
                                <input
                                    className={style.input}
                                    type="text"
                                    value={item.location}
                                    onChange={(e) => {
                                        const updatedAddRC = [...addRC];
                                        updatedAddRC[index].location = e.target.value;
                                        setAddRC(updatedAddRC);
                                    }}
                                />
                            </td>
                            <td className={style.inputRight}>
                                <input
                                    className={codeError?`${style.input} ${style.errotInput}`:style.input}
                                    type="text"
                                    value={item.code}
                                    onChange={(e) => {
                                        const updatedAddRC = [...addRC];
                                        updatedAddRC[index].code = e.target.value;
                                        setAddRC(updatedAddRC);
                                    }}
                                />
                            </td>
                            <img
                                onClick={() => {
                                    const updatedAddRC = addRC.filter((item, i) => i !== index);
                                    setAddRC(updatedAddRC);
                                    setCodeError('')
                                }}
                                className={style.deleteIcon}
                                src={deleteIcon}
                                alt=""
                            />
                        </tr>
                    ))}

                </table>
            </div>

            {modalOpen ? (
                <form className={style.modalForm} onSubmit={handleSubmit}>
                    <div className={style.inpBlock}>
                        <img className={"closeIcon"} src={closeIcon} onClick={() => setModalOpen(false)} alt=""/>
                        <p>Чтобы добавить новый РЦ заполните поля</p>
                        <div className={style.inputsBlock}>
                            <input
                                placeholder={'Название'}
                                onChange={(e) => setLocation(e.target.value)}
                                value={location}
                                className={style.input}
                                type="text"
                            />
                            <input
                                placeholder={'Код'}
                                onChange={(e) => setCode(e.target.value)}
                                value={code}
                                className={style.input}
                                type="text"
                            />
                        </div>
                        <div className={style.btns}>
                            <button className={location.length && code.length ?'add_btn':"sand_btn"} type={'button'} onClick={location.length && code.length ?handleAddRC :null}>
                                Создать запись
                            </button>
                            <button className={addRC.length ? "save_button sand_btn" : 'sand_btn'} type="button"
                                    onClick={() => setModalOpen(false)}>
                                {addRC.length ? "Продолжить":"Отменить"}
                            </button>
                        </div>
                    </div>
                </form>
            ) : null}
            <div className={style.btns}>
                {codeError ? <p className={style.error}>{codeError}</p> : <></>}
                <button className={'add_btn'} type={'button'} onClick={() => setModalOpen(true)}>Добавить</button>

                <button
                    className={addRC.length ? "save_button sand_btn" : 'sand_btn'}
                    onClick={addRC.length ? handleSubmit() : null}
                >
                    Сохранить
                </button>
            </div>
        </div>
    );
}
