import {BaseUrl} from "../url";

export const refreshToken = (BaseUrl, refresh) => {


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ refresh });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw
    };

    fetch(`${BaseUrl}/api/token/refresh/`, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw new Error("Ошибка обновления токена");
            }
            return response.json();
        })
        .then((result) => {
         // const token = result.refresh; // Updated to use the new refreshed token
            //  localStorage.setItem("refreshToken", JSON.stringify(token)); // Update the refresh token in local storage
            const accessToken = result.access; // Extract the new access token
            localStorage.setItem("token", JSON.stringify(accessToken)); // Update the access token in local storage
        })
        .catch((error) => {
            window.location = "/login"
            console.error(error);
        });
};
