import React, {useState} from "react";
import style from '../reference/Reference.module.css'
import GroupsEmployee from "./pagesLigaments/GroupsEmployee/GroupsEmployee";
import stylePage from "../Applications/Application.module.css";
import GroupsCotegorys from "./pagesLigaments/GroupCotegorys/GroupCotegorys";
import GroupRoles from "./pagesLigaments/GroupRoles/GroupRoles";
import Header from "../../components/header/Header";



export default function Ligaments(){
    const [section,setSection]=useState(<GroupsEmployee />)
    const [selected,changeSelected] = useState(0)
    return (
        <div className={stylePage.searcher && style.container}>
            <Header />
            <div className={style.containerLeft}>
                <h2>Связки</h2>
                <ul className={style.navbar}>
                    <li className={selected === 0?style.selected:null} onClick={()=> {
                        setSection(<GroupsEmployee/>)
                        changeSelected(0)
                    }}>Группа — Сотрудник</li>
                    <li  className={selected === 1?style.selected:null}  onClick={()=> {
                        setSection(<GroupsCotegorys/>)
                        changeSelected(1)
                    }}>Группа — Категории</li>
                    <li className={selected === 2?style.selected:null}  onClick={()=> {
                        setSection(<GroupRoles />)
                        changeSelected(2)
                    }}>Группа — Роль</li>
                </ul>
            </div>
            <div className={style.containerRight}>
                {section}
            </div>


        </div>
    )
}