import  { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {refreshToken} from "../../components/Tocen";
import {BaseUrl} from "../../url";

export default function WorkPage() {
    const navigate = useNavigate();
    const userRole = JSON.parse(localStorage.getItem("role"));

    useEffect(() => {
        if (userRole === "admin") {
            navigate('/Referance');
        } else {
            navigate('/Applications/');
        }
    }, [userRole, navigate]);
    const refresh = JSON.parse(localStorage.getItem("refreshToken"));
    useEffect(()=>{
        refreshToken(BaseUrl,refresh)
    })
    return null;
}
