import React from "react";
import style from "../newApplication/Index.module.css";


export default function ModalDelete({setModalWindow,handleDelet , text ,text2}){
    return (  <div className={style.modal_container}>
            <div className={style.modal_window}>

                {/*<p>Вы уверены что хотите из <span className={style.span2}>{text2} </span>  вычислить <span className={style.span}>{text } </span> </p>*/}
                <p>Вы уверены, что хотите исключить пользователя <span className={style.span}>{text} </span> из
                    группы <span className={style.span2}>{text2} </span></p>
                <div className={style.butns}>
                    <button className={'add_btn'} onClick={()=>handleDelet()}>Подтвердить</button>
                    <button className={'sand_btn'}  onClick={() => setModalWindow(<></>)}>Отклонить</button>
                </div>

            </div>
        </div>
    )
}