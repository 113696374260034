import React from "react";
import style from "../newApplication/Index.module.css";
import loadingIMG from "../../../imgs/icons/loader.webp";
import AplicationModal from "../AplicationsModal/AplicationModal";
import AplicationErrorModal from "../AplicationsModal/AplicationErrorModal";
import {BaseUrl} from "../../../url";

export default function KamModal({setModalWindow,handleDelet , id ,setModalAplication}){

    const [loading, setLoading] = React.useState(false);
    const  myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + JSON.parse(localStorage.getItem("token")));

    const requestOptionsDelete = {
        method: "PATCH",
        headers: myHeaders,
        body: JSON.stringify({
            "status": "На рассмотрении"
        })
    };
    myHeaders.append("Content-Type", "application/json");
    function handleChange() {
        setLoading(true)
        fetch(`${BaseUrl}/changerequests/${id}/`, requestOptionsDelete)
            .then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    throw new Error('Ошибка сети');
                }
            })
            .then((data) => {
                setModalAplication(<AplicationModal text={`успешно`}/>)
                setModalWindow(false);

            })
            .catch((error) => {
                console.error('Произошла ошибка:', error);
                let errorMessage = "Произошла ошибка";
                if (error instanceof TypeError) {
                    errorMessage = "Ошибка сети: Проверьте подключение";
                } else if (error instanceof Error) {
                    errorMessage = error.message;
                }
                setModalAplication(<AplicationErrorModal text={errorMessage} />);
            });
    }



  return (  <div className={style.modal_container}>
        <div className={style.modal_window}>

            <p>Вы уверены что хотите отправить заявку #{id}</p>
            {loading ? <img className={'loader'} src={loadingIMG} alt=""/> : null}
                <div className={style.butns}>
                    <button className={'add_btn'} onClick={ handleChange}>Отправить</button>
                    <button className={'sand_btn'}  onClick={() => setModalWindow(false)}>Отменить </button>
                </div>

        </div>
    </div>
)
}