import React from 'react'
import styles from "./AplicationModal.module.css";
import img from "../../../imgs/icons/Vector.svg";

export default function AplicationErrorModal({text}) {

    const [open, setOpen] = React.useState(true);
    setTimeout(()=>setOpen(false) , 5000)
    return open? (
        <div    className={styles.modalErrorBlock}>
            <img  src={img} alt=""/>
            <p>{text}</p>
        </div>
    ):null

}
