import React, { useState, useEffect } from "react";
import style from "./Index.module.css";
import Select from 'react-select';

export default function Filter({ generateFilterOptions, filterOptions, onChangeFilter, setFilterWindow, selectedFilter, serStartF, srtStartFilter }) {
    const [selectedFilters, setSelectedFilters] = useState({});
    const [loading, setLoading] = useState(false);
    serStartF(false)
    useEffect(() => {
        const fetchFilterOptions = async () => {
            setLoading(true);
            try {
                await generateFilterOptions();
            } catch (error) {
                console.error("Error generating filter options:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchFilterOptions();
    }, [selectedFilter]);

    useEffect(() => {
        setSelectedFilters(selectedFilter);
    }, [selectedFilter]);

    const handleSubmit = () => {
        const filteredValues = Object.keys(selectedFilters)
            .filter(key => selectedFilters[key] !== "")
            .reduce((obj, key) => {
                obj[key] = selectedFilters[key];
                return obj;
            }, {});
        onChangeFilter(filteredValues);
        setFilterWindow(false);
    };

    const handleFilterChange = (selectedOption, fieldName) => {
        if (selectedOption && selectedOption.value) {
            const selectedValue = selectedOption.value;
            const updatedFilters = { ...selectedFilters, [fieldName]: selectedValue };
            setSelectedFilters(updatedFilters);
        } else {
            console.error("Selected option or its value is undefined");
        }
    };

    const removeDuplicates = (options) => {
        return options.filter((option, index, self) =>
            index === self.findIndex((t) => t.value === option.value)
        );
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'rgba(155, 56, 220, 0.47)' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.hasValue ? 'rgba(155, 56, 220, 0.47)' : provided.backgroundColor,
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: state.hasValue ? 'white' : provided.color,
        }),
    };

    if (loading) {
        return <div className={style.loading}>Loading...</div>;
    }
    return (
        <div className={style.filter}>
            {filterOptions && Object.keys(filterOptions).map((fieldName, index) => (
                <div className={style.container} key={index}>
                    <div>
                        {fieldName}
                        <Select
                            options={removeDuplicates(filterOptions[fieldName].map(value => ({ value, label: value })))}
                            value={selectedFilters[fieldName] ? { value: selectedFilters[fieldName], label: selectedFilters[fieldName] } : null}
                            onChange={(selectedOption) => handleFilterChange(selectedOption, fieldName)}
                            placeholder="Выберите..."
                            styles={customStyles}
                        />
                    </div>
                </div>
            ))}
            <div className={style.btns}>
                <button className={"sand_btn"} onClick={() => setFilterWindow(false)}>Отменить</button>
                <button className={"sand_btn"} onClick={() => {
                    srtStartFilter(false)
                    serStartF(true)
                    setSelectedFilters('')
                }}>Сбросить</button>
            </div>
            <button className={"sand_btn save_button"} onClick={handleSubmit}>Применить</button>
        </div>
    );
}
