import React, { useState, useEffect, useCallback } from "react";
import { debounce } from 'lodash';
import style from '../reference/components/DistributionCenter/DistributionCenter.module.css';
import stulePage from '../Applications/Application.module.css';
import filterIcon from '../../imgs/icons/Frame 1948755655.png';
import sortIcon from '../../imgs/icons/Frame 1948755654.png';
import InProcessing from '../../imgs/icons/Property 1=Default2.png';
import Uploaded from '../../imgs/icons/Property 1=Default3.png';
import approved from '../../imgs/icons/одобрено.svg';
import rejected from '../../imgs/icons/Vector.svg';
import loading from "../../imgs/icons/loader.webp";
import under_review from '../../imgs/icons/Property 1=Default1.png';
import draft from '../../imgs/icons/Property 1=Default0.png';
import draftx from '../../imgs/photo_2024-07-15 20.05.39.jpeg';
import Filter from "../../components/filter/Index";
import Header from "../../components/header/Header";
import { useNavigate } from "react-router-dom";
import { refreshToken } from "../../components/Tocen";
import { BaseUrl } from "../../url";
import propertyIcon from '../../imgs/Property 1=Variant2 (2).png';
import { uniqBy } from 'lodash';


export default function History() {
    const [locationInfo, setLocationInfo] = useState([]);
    const [searchQueryGet, setSearchQueryGet] = useState('');
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
    const [loadingData, setLoadingData] = useState(true);
    const [filterWindow, setFilterWindow] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState({});
    const [filterOptions, setFilterOptions] = useState([]);
    const [sortDirection, setSortDirection] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("timestamp");
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();
    const myHeaders = new Headers();
    const token = localStorage.getItem("token");
    const refresh = JSON.parse(localStorage.getItem("refreshToken"));
    const [params,setParams] = useState(null)
    const [startFilter,srtStartFilter] = useState(false)
    const [total,setTotal] = useState(1)
    const [startF,serStartF] =useState(true);
    const [uniqueEntries, setUniqueEntries] = useState(new Set());
    const [isRequesting, setIsRequesting] = useState(false);

    if (!token) {
        navigate('/Login');
    } else {
        try {
            const parsedToken = JSON.parse(token);
            myHeaders.append("Authorization", "Bearer " + parsedToken);
        } catch (error) {
            console.error("Ошибка парсинга токена:", error);
            navigate('/Login');
        }
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
    };
    const statusOrder = {
        "Черновик": 1,
        "На рассмотрении": 2,
        "Одобрено": 3,
        "Отклонено": 4,
        "В Обработке": 5,
        "Загружено": 6 ,
        "Скорректировано": 7
    };

    const filterFieldMappings = {
        "Автор": "author",
        "Категория": "category",
        "РЦ": "warehouse",
        "Текущ_квант": "current_quant",
        "Квант": "quant",
        "Max_квант": "max_quant",
        "ОТЗ": "turnover",
        "Статус": "status"
    };
    useEffect(()=>{
        params === null && fetch(`${BaseUrl}/actionlogs/filterOptions/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                setParams(result)
            })
            .catch((error) => {
                console.error("Error fetching data:", error);

            })

    },[selectedFilter])
    const loadData = useCallback((pageNum = 1, reset = false, sortColumn = sortedColumn, sortDir = sortDirection) => {
        if (isRequesting) {
            console.log(`Request is already in progress.`);
            return;
        }
        setIsRequesting(true);
        setLoadingData(true);

        const filters = Object.keys(selectedFilter)
            .map(key => `${filterFieldMappings[key]}=${selectedFilter[key]}`)
            .join('&');

        let ordering = '';
        if (sortColumn === 'timestamp') {
            ordering = `&ordering=${sortDir === 'desc' ? '-' : ''}timestamp`;
        } else if (sortColumn === 'status') {
            ordering = `&ordering=${sortDir === 'desc' ? '' : 'status'}`;
        } else if (sortColumn) {
            ordering = `&ordering=${sortDir === 'desc' ? '-' : ''}${sortColumn}`;
        }

        let url = `${BaseUrl}/actionlogs/?page=${pageNum}`;

        if (debouncedSearchQuery) {
            url += `&search=${encodeURIComponent(debouncedSearchQuery)}`;
        }

        if (filters) url += `&${filters}`;
        if (ordering) url += `&${ordering}`;

        console.log("Request URL:", url);

        fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setTotal(result.total_pages);
                if (!result || !Array.isArray(result.results)) {
                    console.error("Invalid API response:", result);
                    setLocationInfo([]);
                    setHasMore(false);
                    setLoadingData(false);
                    setIsRequesting(false);
                    return;
                }
                if (reset) {
                    setLocationInfo(result.results);
                    setUniqueEntries(new Set(result.results.map(entry => entry.id)));
                } else {
                    const newEntries = result.results.filter(entry => !uniqueEntries.has(entry.id));
                    setLocationInfo(prevInfo => [...prevInfo, ...newEntries]);
                    setUniqueEntries(prevSet => {
                        const newSet = new Set(prevSet);
                        newEntries.forEach(entry => newSet.add(entry.id));
                        return newSet;
                    });
                }
                setHasMore(result.next !== null);
                setLoadingData(false);
                setIsRequesting(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLocationInfo([]);
                setHasMore(false);
                setLoadingData(false);
                setIsRequesting(false);
            });
    }, [debouncedSearchQuery, selectedFilter, uniqueEntries]);

    const loadMore = useCallback(() => {

        if (hasMore && !loadingData) {
            const nextPage = total > page ? page + 1 : debouncedSearchQuery?1: 1;
            setPage(nextPage);
            loadData(nextPage, false, sortedColumn, sortDirection);
        }
    }, [hasMore, loadingData, loadData, page, total, sortedColumn, sortDirection]);

    useEffect(() => {
        setPage(1);
        setUniqueEntries(new Set());
        loadData(1, true, sortedColumn, sortDirection);
    }, [debouncedSearchQuery, selectedFilter, sortedColumn, sortDirection]);

    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                loadMore();
            }
        };
        setLoadingData(false);

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [page, hasMore, loadMore]);

    const getStatusDetails = (e) => {
        const statusDetails = e.details;
        let statusValue, statusImage;

        if (statusDetails.Статус_заявки === "на рассмотрении" || statusDetails.Статус === "на рассмотрении") {
            statusValue = "на рассмотрении";
            statusImage = "under_review_img.jpg";
        } else if (statusDetails.Статус_заявки === "Черновик" || statusDetails.Статус === "Черновик") {
            statusValue = "Черновик";
            statusImage = "draft_img.jpg";
        } else if (statusDetails.Статус_заявки === "Одобрено" || statusDetails.Статус === "Одобрено") {
            statusValue = "Одобрено";
            statusImage = "approved_img.jpg";
        } else if (statusDetails.Статус_заявки === "Отклонено" || statusDetails.Статус === "Отклонено") {
            statusValue = "Отклонено";
            statusImage = "rejected_img.jpg";
        } else if (statusDetails.Статус_заявки === "В Обработке" || statusDetails.Статус === "В Обработке") {
            statusValue = "В Обработке";
            statusImage = "inFusation_img.jpg";
        } else if (statusDetails.Статус_заявки === "Загружено" || statusDetails.Статус === "Загружено") {
            statusValue = "Загружено";
            statusImage = "dounloaded_img.jpg";
        } else if (statusDetails.Статус_заявки === "Скорректировано" || statusDetails.Статус === "Скорректировано") {
            statusValue = "Скорректировано";
            statusImage = "Property 1=Variant2 (2).png";
        } else {
            statusValue = null;
            statusImage = null;
        }

        return { status: statusValue, image: statusImage };
    };

    const handleSortByStatus = () => {
        const newSortDirection = sortDirection === "asc" ? "desc" : "asc";
        setSortDirection(newSortDirection);
        setSortedColumn("status");
        setPage(1);
        setLocationInfo([]);
        setUniqueEntries(new Set());
        loadData(1, true, "status", newSortDirection);
    };

    const handleSortByDate = () => {
        const newSortDirection = sortDirection === "desc" ? "asc" : "desc";
        setSortDirection(newSortDirection);
        setSortedColumn("timestamp");
        setPage(1);
        setLocationInfo([]);
        setUniqueEntries(new Set());
        loadData(1, true, "timestamp", newSortDirection);
    };
    const hasSelectedFilters = Object.values(selectedFilter).some(value => value !== undefined && value !== '');

    // Создаем функцию debounce для поиска
    const debouncedSearch = useCallback(
        debounce((value) => {
            setDebouncedSearchQuery(value);
        }, 1000),
        []
    );

    // Обновляем обработчик изменения поискового запроса
    const handleSearchChange = (e) => {
        const value = typeof e.target.value === "number" ? (+e.target.value).toFixed(0) : e.target.value;
        setSearchQueryGet(value);
        debouncedSearch(value);
    };

    return (
        <>
            <Header />
            <div className={style.container && stulePage.container}>
                <div className={stulePage.fixBlock}>
                    <div className={stulePage.searchBlock}>
                        <input
                            type="search"
                            placeholder={'Поиск'}
                            value={searchQueryGet}
                            onChange={handleSearchChange}
                        />
                        <button  className={`${stulePage.filterBotton} ${hasSelectedFilters ? stulePage.filterBottonActive : ''}`}
                                 onClick={() => setFilterWindow(!filterWindow ? true : false)}><img src={filterIcon}
                                                                                                   alt=""/>
                        </button>
                        {filterWindow ? <Filter serStartF={serStartF} srtStartFilter={srtStartFilter}  filterOptions={params} onChangeFilter={setSelectedFilter} selectedFilter={selectedFilter} setFilterWindow={setFilterWindow} /> : null}

                        <button className={style.filter}><img src={sortIcon} alt=""/>
                            <ul className={style.filterContainer}>
                                <li onClick={handleSortByDate}>
                                    по дате {sortedColumn === "timestamp" && (sortDirection === 'desc' ? '▼' : '▲')}
                                </li>
                                <li onClick={handleSortByStatus}>
                                    по статусу {sortedColumn === "status" && (sortDirection === 'desc' ? '▼' : '▲')}
                                </li>
                            </ul>
                        </button>
                    </div>
                </div>

                <table className={stulePage.table}>
                    <tbody>
                    <tr className={stulePage.tableTitles}>
                        <td className={stulePage.input}>Номер <br/> заявки</td>
                        <td className={stulePage.input}>Автор</td>
                        <td className={stulePage.input}>Белый <br/> список</td>
                        <td className={stulePage.input}>Категория</td>
                        <td className={stulePage.input}>РЦ</td>
                        <td className={`${stulePage.input} ${stulePage.cuant}`}>Квант</td>
                        <td className={`${stulePage.input} ${stulePage.Cause}`}>Причина</td>
                        <td className={`${stulePage.input} ${stulePage.Cause}`}>Причина супервайзера</td>
                        <td className={`${stulePage.input} `}>Квант супервайзера</td>
                        <td className={`${stulePage.input} ${stulePage.cuantX}`}>Текущ <br/> квант</td>
                        <td className={stulePage.input}>Max <br/> квант</td>
                        <td className={stulePage.input}>Дельта, %</td>
                        <td className={stulePage.input}>Дельта,шт</td>
                        <td className={stulePage.input}>Продажи 30 дней</td>
                        <td className={stulePage.input}>Прогноз</td>
                        <td className={stulePage.input}>Остаток</td>
                        <td className={stulePage.input}>Мерчант</td>
                        <td className={stulePage.input}>Статус</td>
                        <td className={stulePage.input}>Срок кванта</td>
                        <td className={stulePage.input}>Дата / время</td>
                        <td className={stulePage.input}>Супервайзер</td>
                    </tr>
                    {loadingData && page === 1 ? (
                        <tr>
                            <td colSpan="22" className={stulePage.input}>
                                <img src={loading} alt="Loading..."/>
                            </td>
                        </tr>
                    ) : !locationInfo.length ? (
                        <tr>
                            <td colSpan="22">
                                <p>Пусто</p>
                            </td>
                        </tr>
                    ) : locationInfo.map(e => {
                        const details = e.details;
                        return (
                            <tr key={e.id}>
                                <td className={stulePage.input}><p>{details.Заявка_ID || '-'}</p></td>

                                <td className={stulePage.input}>
                                    <p>{e.details.KAM?.length <= 3 ? e.details.KAM[0] : e.details.KAM} {e.details.KAM?.length <= 3 ? e.details.KAM[1] : ''} {e.details.KAM?.length <= 3 ? e.details.KAM[2] : ''} </p>
                                </td>
                                <td className={stulePage.input}>
                                    {details.Merchant_flag && <img src={draftx}/>}
                                </td>
                                <td className={stulePage.input}><p>{details.Категория || '-'}</p></td>
                                <td className={stulePage.input}><p>{details.Склад || '-'}</p></td>
                                <td className={`${stulePage.input} ${stulePage.cuant}`}>
                                    <p>{details.Новое_значение || details.Текущее_значение || '-'}</p>
                                </td>
                                <td className={stulePage.input}><p>{details.Причина || '-'}</p></td>
                                <td className={stulePage.input}><p>{details.Комментарий_супервайзера || '-'}</p></td>
                                <td className={stulePage.input}><p>{details.Одобренное_значение || '-'}</p></td>

                                <td className={`${stulePage.input} ${stulePage.cuantX}`}>
                                    <p>{details.Текущее_количество || '-'}</p>
                                </td>
                                <td className={stulePage.input}>
                                    <p>{details.Максимально_запрошенное || '-'}</p></td>
                                <td className={stulePage.input}>
                                    <pre>{`${e.details.Дельта ? e.details.Дельта.toFixed(1) + "%" : '-'} `} </pre>
                                </td>
                                <td className={stulePage.input}>
                                    <p>{e.details.Изменение || '-'} </p>
                                </td>
                                <td className={stulePage.input}>
                                    <p>{e.details.Прогноз_продаж ? e.details.Прогноз_продаж.toFixed(1) : '-'}</p></td>
                                <td className={stulePage.input}>
                                    <p>{e.details.Продажи_за_30_дней ? e.details.Продажи_за_30_дней.toFixed(1) : '-'}</p>
                                </td>
                                <td className={stulePage.input}><p>{e.details.Остатки || '-'}</p></td>
                                <td className={stulePage.input}>{e.details.Merchant_ID || '-'}</td>
                                <td className={stulePage.input}>
                                    <img
                                        src={
                                            (details.Статус_заявки === "На рассмотрении" || details.Статус === "На рассмотрении") ? InProcessing :
                                                (details.Статус_заявки === "Черновик" || details.Статус === "Черновик") ? draft :
                                                    (details.Статус_заявки === "Одобрено" || details.Статус === 'Одобрено') ? approved :
                                                        (details.Статус_заявки === "Отклонено" || details.Статус === "Отклонено"||details.Статус ===  "Отменен") ? rejected :
                                                            (details.Статус_заявки === "В Обработке" || details.Статус === "В Обработке") ? under_review :
                                                                (details.Статус_заявки === "Загружено" || details.Статус === "Загружено") ? Uploaded :
                                                                    (details.Статус_заявки === "Скорректировано" || details.Статус === "Скорректировано") ? propertyIcon :
                                                                        '-'
                                        }
                                        alt="status"
                                        title={
                                        (details.Статус_заявки === "На рассмотрении" || details.Статус === "На рассмотрении") ? "2 - На рассмотрении" :
                                            (details.Статус_заявки === "Черновик" || details.Статус === "Черновик") ? "1 - Черновик" :
                                                (details.Статус_заявки === "Одобрено" || details.Статус === 'Одобрено') ? "3 - Одобрено" :
                                                    (details.Статус_заявки === "Отклонено" || details.Статус === "Отклонено" ||details.Статус ===  "Отменен") ? "4 - Отклонено" :
                                                        (details.Статус_заявки === "В Обработке" || details.Статус === "В Обработке") ? "5 - В Обработке" :
                                                            (details.Статус_заявки === "Загружено" || details.Статус === "Загружено") ? "6 - Загружено" :
                                                                (details.Статус_заявки === "Скорректировано" || details.Статус === "Скорректировано") ? "7 - Скорректировано" :
                                                                    '-'}
                                    />
                                </td>
                                <td className={stulePage.input}>
                                    {details.Срок_жизни_кванта || "-"}
                                </td>
                                <td className={stulePage.input}>
                                    <p>{e.timestamp.slice(0, 10) + " " + e.timestamp.slice(11, 16)}</p>
                                </td>
                                <td className={stulePage.input}>
                                    <p>{e.details.Супервайзер?.length <= 3 ? e.details.Супервайзер[0] : '-'} {e.details.Супервайзер?.length <= 3 ? e.details.Супервайзер[1] : ''} {e.details.Супервайзер?.length <= 3 ? e.details.Супервайзер[2] : ''} </p>
                                </td>

                            </tr>
                        );
                    })}
                    {loadingData && page > 1 && (
                        <tr>
                            <td colSpan="22" className={stulePage.input}>
                                <img src={loading} alt="Loading..."/>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
